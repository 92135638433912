import React from "react";
import { connect } from "react-redux";

const SelectedModules = (props) => {
  return (
    <div>
      {/* <h3 className="section-title">Selected Modules</h3> */}
      {props.selectedModule !== undefined ? (
        <div  className="selected-individual-module">
          <p className="name">{props.selectedModule.name}</p>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedModule: state.electrical.selectedModule,
  };
};

export default connect(mapStateToProps)(SelectedModules);
