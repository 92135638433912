const initialState = {
  modules: [],
  inverters: [],
  manufacturers: [],
  displayedModules: [],
  selectedModule: undefined,
  selectedInverter: undefined,
  selectedManufacturer: "",
  targetDcAcRatio: 1
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVERTERS":
      return {
        ...state,
        inverters: action.payload,
      };
    case "GET_ALL_MODULES":
      return {
        ...state,
        modules: action.payload,
      };
    case "GET_ALL_MANUFACTURERS":
      return {
        ...state,
        manufacturers: action.payload,
      };
    case "SET_DISPLAYED_MODULES":
      return {
        ...state,
        displayedModules: action.payload,
        selectedManufacturer: action.manufacturer
      };
      case "CLEAR_SELECTED":
        return {
          ...state,
          selectedManufacturer: ""
        }
        case "CLEAR_DISPLAYED":
          return {
            ...state,
            displayedModules: []
          }

    case "SET_SELECTED_MODULE":
      return {
        ...state,selectedModule: action.payload
      }
      case "SET_SELECTED_INVERTER":
      return {
        ...state,selectedInverter: action.payload
      }
      case "SET_DCAC_RATIO":
      return {
        ...state,targetDcAcRatio: action.payload
      }

    default:
      return state;
  }
};

export default projectReducer;
