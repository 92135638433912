import React from "react";
import scss from "./deleteProjectModal.module.scss";
const Modal = (props) => {
  return (
    <div className={scss["modal-container"]}>
      <h4 className={scss["modal-title"]}>{props.title}</h4>
      <p className={scss["modal-text"]}>{props.text}</p>
      {props.form}
      <div className={scss["modal-buttons"]}>
        <button className={scss["close"]} onClick={props.toggleModal}>
          No, keep it.
        </button>
        <button className={scss["delete"]} onClick={props.specialFunction}>
          {props.specialButton}
        </button>
      </div>

      {props.content}
    </div>
  );
};

export default Modal;
