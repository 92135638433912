export const setLoadingTrue = () => (dispatch) => {
    dispatch( {
        type: "SET_LOADING_TRUE"
    })
}

export const setLoadingFalse= () => (dispatch) => {
    dispatch( {
        type: "SET_LOADING_FALSE"
    })
}

export const setViewingFalse= () => (dispatch) => {
    dispatch( {
        type: "SET_VIEWING_FALSE"
    })
}
export const setViewingTrue= () => (dispatch) => {
    dispatch( {
        type: "SET_VIEWING_TRUE"
    })
}

export const toggleLoading = () => (dispatch) => {
  
    dispatch({
        type: "TOGGLE_LOADING"
    })
}