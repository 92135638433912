import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { deleteSavedCfg,setSATRowCfg } from "Redux/actions/ArmadilloActions";
//components
import DeleteProjectModal from "components/Routes/user/projects/DeleteProjectModal";
import EditForm from "./EditForm";

import scss from "./editCfgs.module.scss";

const Savedcfg = (props) => {
  const dispatch = useDispatch();
  const { cfgData, savedCfg } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const deleteHandler = () => {
    setIsDeleting(!isDeleting);
  };
  const editingHandler = () => {
    setIsEditing(!isEditing);
    dispatch(setSATRowCfg(cfgData))
  };
  return (
    <div className={scss["saved-cfg"]}>
      {isDeleting ? (
        <DeleteProjectModal
          title={`Delete ${cfgData.sat_table_name}`}
          text={`Are you sure you want to delete this saved tracker?`}
          specialButton={`Yes, delete it.`}
          specialFunction={() => dispatch(deleteSavedCfg(savedCfg))}
          toggleModal={deleteHandler}
        />
      ) : null}
      <p className={scss["tracker-name"]}>{cfgData.sat_table_name}</p>
      {isEditing ? (
        <div className="edit-form-container">
          <EditForm satRow={cfgData} />
          <button className={scss["cancel-new-cfg"]} onClick={editingHandler}>
            Cancel Editing
          </button>
        </div>
      ) : null}
      <div className={scss["tracker-edit-buttons"]}>
        <button onClick={editingHandler} className={scss["edit-tracker"]}>
          Edit
        </button>
        <button onClick={deleteHandler} className={scss["delete-tracker"]}>
          Delete
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    saved: state.armadillo.savedSatRowCfgs,
    user: state.user.userData,
  };
};

export default connect(mapStateToProps)(Savedcfg);
