import React, { useState } from "react";
import scss from "./ProjectListItem.module.scss";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DeleteProjectModal from './DeleteProjectModal';
import { getFiles } from "Redux/actions/userActions";
import { deleteProject } from "Redux/actions/ArmadilloActions";


const ProjectListItem = (props) => {
  const { project } = props;
  let Project = project.project_data;
  let project_name = Project.ProjectData.project_name;
  let inverter = Project.ProjectData.inverter_name;
  let module = Project.ProjectData.module_name;
  const dispatch = useDispatch();
  const [displayDelete, setDelete]= useState(false);

  const deleteHandler = () => {
    setDelete(!displayDelete)
  }

  return (
    <div className={scss["project-list-item"]}>
      {displayDelete ?  <DeleteProjectModal
          title={`Delete ${project_name}?`}
          text={`Are you sure you want to delete this project?`}
          specialButton="Yes, delete it."
          specialFunction={() => dispatch(deleteProject(project))}
          toggleModal={deleteHandler}
        /> : null}
      <div className={scss["project-info-container"]}>
        <h1 className={scss["project-name"]}> {project_name} </h1>
        <div className={scss["electric-data"]}>
          <h3 className={scss["stat"]}>{module}</h3>
          <h3 className={scss["stat"]}>{inverter}</h3>
        </div>
   
      </div>

      <div className={scss["project-list-item-buttons"]}>
        <Link
          onClick={() => dispatch(getFiles(project.id, props.armadillo,props.user))}
          to={`/${props.user.username}/createProject`}
        >
          <button className={scss["view-project"]}>View project</button>
        </Link>
        <button className={scss["share-project"]}>Share project</button>
        <button
          className={scss["delete-project"]}
          onClick={deleteHandler}
        >
          Delete project
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    armadillo: state.armadillo,
    user: state.user.userData,
  };
};

export default connect(mapStateToProps)(ProjectListItem);
