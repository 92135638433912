import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import { getUserIp } from "Redux/actions/userActions";
import { registerUser } from "Redux/actions/authenticationActions";
import {
  errorMessage,
} from "Redux/actions/notificationActions";
//components
import Modal from "components/UI/Modal";

const Register = (props) => {
  const { toggle } = props;
  const dispatch = useDispatch();
  
  const [registrationCredentials, setCredentials] = useState({
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    organizationName: "",
    organizationType: "",
    organizationRole: "",
    EULAData: "",
  });
  const [agreeToTerms, setTerms] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowModal(!showModal);
  };

  const inputHandler = (e) => {
    setCredentials({
      ...registrationCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const configureEULAData = () => {
    let date = new Date().toString();
    let version = "EULA V 0.0";
    setCredentials({
      ...registrationCredentials,
      EULAData: `${version} signed ${date} from ${props.user.userIP}`,
    });
    return;
  };

  const termsHandler = () => {
    setTerms(!agreeToTerms);
    configureEULAData();
  };
  const acceptTerms = () => {
    termsHandler();
    toggleModal();
  };

  const registrationSubmitHandler = async (e) => {
    e.preventDefault();
    //eslint-disable-next-line
    const passRegex = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
    );
    if (registrationCredentials.username) {
      if (agreeToTerms) {
        if (registrationCredentials.password === "") {
          errorMessage("You need a password, dawg.");
        }
        if (registrationCredentials.password !== confirmPassword) {
          errorMessage("The passwords do not match");
        }
        if (!passRegex.test(registrationCredentials.password)) {
          errorMessage(
            "Password must contain at least 8 characters, one upper case alphabetical character, a special character, and a number.",
          );
        } else {
          let registrationResult = await dispatch(
            registerUser(registrationCredentials),
          );
          if (registrationResult) {
            console.log(registrationResult)
            props.toggle()

          }
        }
      } else {
        errorMessage("You must agree to all the terms and stuff.");
      }
    } else {
      errorMessage("You gotta fill the form out.");
    }
  };

  useEffect(() => {
    dispatch(getUserIp());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="user-registration">
      {showModal ? (
        <Modal
          title="This is the agreement"
          text="Give us money for services and don't be a dick. Also, you owe us 1 baby."
          specialButton="I agree"
          specialFunction={acceptTerms}
          toggleModal={toggleModal}
        />
      ) : null}
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form>
        <div className="input-container">
          <label htmlFor="email">E-mail</label>
          <input
            type="text"
            className="auth-input"
            name="email"
            value={registrationCredentials.email}
            onInput={inputHandler}
            required
          />
        </div>

        <div className="input-container">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            className="auth-input"
            name="firstName"
            value={registrationCredentials.firstName}
            onInput={inputHandler}
            required
          />
        </div>

        <div className="input-container">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            className="auth-input"
            name="lastName"
            value={registrationCredentials.lastName}
            onInput={inputHandler}
            required
          />
        </div>

        <div className="input-container">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="auth-input"
            name="username"
            value={registrationCredentials.username}
            onInput={inputHandler}
          />
        </div>

        <div className="input-container">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="auth-input"
            name="password"
            value={registrationCredentials.password}
            onInput={inputHandler}
            required
          />
        </div>

        <div className="input-container">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            className="auth-input"
            name="confirmPassword"
            value={confirmPassword}
            onInput={confirmPasswordHandler}
            required
          />
        </div>

        <div className="input-container">
          <label htmlFor="organizationName">Organization name</label>
          <input
            type="text"
            className="auth-input"
            name="organizationName"
            value={registrationCredentials.organizationName}
            onInput={inputHandler}
            required
          />
        </div>

        <div className="input-container">
          <label htmlFor="organizationType">Organization Type</label>
          <select
            name="organizationType"
            required
            onChange={inputHandler}
            defaultValue={registrationCredentials.organizationType}
            id="type"
          >
            <option value="" hidden>Select organization type</option>
            <option value="Nonprofit">Nonprofit</option>
            <option value="Government">Government</option>
            <option value="Developer">Developer</option>
            <option value="EPC">EPC</option>
            <option value="Owner/Operator">Owner/Operator</option>
            <option value="Engineering Consultant">
              Engineering Consultant
            </option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="input-container">
          <label htmlFor="organizationRole">Organization Role</label>
          <select
            name="organizationRole"
            required
            onChange={inputHandler}
            defaultValue={registrationCredentials.organizationRole}
            id="role"
          >
            <option value="" hidden>Select organization role</option>
            <option value="Management">Management</option>
            <option value="Operations">Operations</option>
            <option value="Engineering">Engineering</option>
            <option value="Development">Development</option>
            <option value="Regulatory">Regulatory</option>
            <option value="Other">Other</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="input-container">
          <button className="view-terms" onClick={toggleModal}>
            See Agreement
          </button>

          <label htmlFor="agreeToTerms">I Agree to the things</label>
          <input
            type="checkbox"
            checked={!!agreeToTerms}
            className="auth-input"
            name="agreeToTerms"
            value={agreeToTerms}
            onChange={termsHandler}
            required
          />
        </div>
        <div className="input-container">
          <button
            onClick={registrationSubmitHandler}
            className="registration-button"
          >
            Register
          </button>
        </div>
      </form>
      <p className="have-account-text">
        Already have an account? Log in <span onClick={toggle}>here</span>{" "}
      </p>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Register);
