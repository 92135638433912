import { EditableGeoJsonLayer } from "@nebula.gl/layers";


export const deckStyles = {
  margin: "0 auto",
  position: "relative",
  zIndex: "1",
};

export const skyLayer = {
  id: "sky",
  type: "sky",
  paint: {
    "sky-type": "atmosphere",
    "sky-atmosphere-sun": [1.0, 1.0],
    "sky-atmosphere-sun-intensity": 8,
  },
};

export const projectLandLayer = (geoData, setGeoData) => {
  
 
  return new EditableGeoJsonLayer({
    id: "geojson",
    data: geoData.geojson,
    mode: "view",
    opacity: 0.055,
    onEdit: ({ updatedData }) => {
      setGeoData((geoData) => ({ ...geoData, geojson: updatedData }));
    },
    _subLayerProps: {
      geojson: {
        getFillColor: [0, 229, 255],
        getLineColor: [0, 0, 0],
      },
    },
  });
};

export const keepoutLayer = (keepouts, setKeepouts) => {
  return new EditableGeoJsonLayer({
    id: "keepouts",
    data: keepouts.geojson,
    mode: "view",
    opacity: 0.8,
    extruded: true,
    getElevation: 10000,
    onEdit: ({ updatedData }) => {
      setKeepouts((geoData) => ({ ...geoData, geojson: updatedData }));
    },
    _subLayerProps: {
      geojson: {
        getFillColor: [255, 0, 0],
        getLineColor: [0, 0, 0],
      },
    },
  });
};

export const getTableLayer = (tableLocations, setTableLocations) => {
  return new EditableGeoJsonLayer({
    id: "tableLocations",
    data: tableLocations.geojson,
    mode: "view",
    extruded: true,
    stroked: false,
    getElevation: 100,
    onEdit: ({ updatedData }) => {
      console.log(updatedData);

      setTableLocations((tableLocations) => ({
        ...tableLocations,
        geojson: updatedData,
      }));
    },
    _subLayerProps: {
      geojson: {
        getFillColor: [0, 0, 0],
        getLineColor: [0, 0, 0],
      },
    },
  });
};

export const getPileLayer = (pilePoints, setPilePoints) => {
  return new EditableGeoJsonLayer({
    id: "pilePoints",
    data: pilePoints.geojson,
    mode: "view",
    stroked: false,
    onEdit: ({ updatedData }) => {
      console.log(updatedData);
      setPilePoints((pilePoints) => ({
        ...pilePoints,
        geojson: updatedData,
      }));
    },
    _subLayerProps: {
      geojson: {
        getFillColor: [255, 255, 255],
        getLineColor: [255, 255, 255],
      },
    },
  });
};
