import React, { useState } from "react";
import  {forgotPassword} from 'Redux/actions/authenticationActions';
const PasswordRecovery = (props) => {
  const [email,setEmail] = useState("");
  const credentialHandler = (e) => {
    setEmail(e.target.value);
  };

  const submitHandler = (e) => {
      e.preventDefault();
      forgotPassword(email);
      
  }
  return (
    <div className="password-recovery">
      <h1 className="section-title">Password Recovery</h1>

      <form className="recovery-form">
       

        <div className="input-container">
          <h4 className="recovery-email">
            What is the email address assoicated with your account?
          </h4>
          <input
            type="text"
            name="email"
            value={email}
            onChange={credentialHandler}
            placeholder="Enter email"
            className="security-input"
          />
        </div>

       
       <button onClick={submitHandler} className="submit-recovery">Submit</button>
       
            
       

      </form>
    </div>
  );
};

export default PasswordRecovery;
