import React from "react";
import { connect } from "react-redux";
import "./styles/global.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//components
import Routes from "./components/Routes/Routes";

function App() {
  return (
    <div className="container">
       <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
     
      <Routes />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    tables: state.tables.selectedTables,
  };
};

export default connect(mapStateToProps)(App);
