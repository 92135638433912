import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import scss from "./editForm.module.scss";
import { getModuleById } from "Redux/actions/electrical/electricalData";
import { updateSavedCfg, setSATRowCfg } from "Redux/actions/ArmadilloActions";
import { getTrackerDimensions } from "Redux/actions/armadillo/getTrackerDimensions";
import ModuleSearch from "components/modules/ModuleSearch";

const Editform = (props) => {
  const { satRow } = props;
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const savedIndex = props.saved.findIndex(
      (i) => i.tableCfg.sat_table_name === satRowCfg.sat_table_name,
    );
    const table = { ...props.saved[savedIndex] };
    table.tableCfg = satRowCfg;
    const id = props.saved[savedIndex].id;
    const users_id = props.saved[savedIndex].users_id;
    console.log(id);
    const sender = { table: table, id: id, users_id: users_id };
    dispatch(updateSavedCfg(sender));
  };
  const [satRowCfg, setCfg] = useState(satRow);

  const simpleChangeHandler = (e) => {
    setCfg({
      ...satRowCfg,
      [e.target.name]: e.target.valueAsNumber || e.target.value,
    });
  };
  const trackerChangeHandler = (e) => {
    dispatch(getTrackerDimensions(props.selectedModule, satRowCfg));
  };

  useEffect(() => {
    if (props.selectedModule === undefined) {
      dispatch(getModuleById(satRowCfg.module_id));
    }
    //eslint-disable-next-line
  }, [satRowCfg, props.selectedModule]);

  useEffect(() => {
    let copy = { ...props.armadillo };
    setCfg(copy);
    //eslint-disable-next-line
  }, [props.armadillo.string_length, props.armadillo.strings_per_table]);

  return (
    <form className={scss["tracker-cfg-form"]}>
      <div className={scss["input-container"]}>
        <label htmlFor="table_name">Table Name</label>
        <input
          type="text"
          placeholder={
            satRowCfg.sat_table_name === ""
              ? "enter table name"
              : satRowCfg.sat_table_name
          }
          name="sat_table_name"
          value={satRowCfg.sat_table_name}
          onChange={simpleChangeHandler}
          onBlur={() => dispatch(setSATRowCfg(satRowCfg))}
        />
      </div>
      <ModuleSearch />
      <div className={scss["input-container"]}>
        <label htmlFor="string_length">String Length</label>
        <input
          type="number"
          name="string_length"
          value={satRowCfg.string_length}
          onChange={simpleChangeHandler}
          onBlur={trackerChangeHandler}
        />
      </div>

      <div className={scss["input-container"]}>
        <label htmlFor="strings_per_table">Strings Per Table</label>
        <input
          type="number"
          name="strings_per_table"
          value={satRowCfg.strings_per_table}
          onChange={simpleChangeHandler}
          onBlur={trackerChangeHandler}
        />
      </div>
      <div className={scss["mechanical-inputs"]}>
        <h4>Simple Mechanical</h4>
        <div className={scss["input-container"]}>
          <label htmlFor="sat_row_length">SAT row length</label>
          <input
            type="number"
            placeholder={satRowCfg.sat_row_length}
            name="sat_row_length"
            value={parseFloat(satRowCfg.sat_row_length)}
            onChange={simpleChangeHandler}
          />
        </div>
        <div className={scss["input-container"]}>
          <label htmlFor="sat_row_width">SAT row width</label>
          <input
            type="number"
            placeholder={satRowCfg.sat_row_width}
            name="sat_row_width"
            value={parseFloat(satRowCfg.sat_row_width)}
            onChange={simpleChangeHandler}
          />
        </div>
        <div className={scss["input-container"]}>
          <label htmlFor="sat_row_dc_power">SAT row DC power</label>
          <input
            type="number"
            placeholder={satRowCfg.sat_row_dc_power}
            name="sat_row_dc_power"
            value={satRowCfg.sat_row_dc_power}
            onChange={simpleChangeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="number_of_piles">Number of piles</label>
          <input
            type="number"
            placeholder={satRowCfg.n_piles}
            name="n_piles"
            value={satRowCfg.n_piles}
            onChange={simpleChangeHandler}
          />
        </div>
      </div>
      <button
        className={scss["save-new-cfg"]}
        onClick={(e) => submitHandler(e)}
      >
        Save This Configuration
      </button>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    manufacturers: state.electrical.manufacturers,
    user: state.user.userData,
    selectedModule: state.electrical.selectedModule,
    saved: state.user.savedSatRowCfgs,
    armadillo: state.armadillo.SATRowCfg,
  };
};
export default connect(mapStateToProps)(Editform);
