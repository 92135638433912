import React from "react";
import scss from "./backdrop.module.scss";

const Backdrop = (props) => {
//   const [show, setShow] = useState(props.show);

  const toggleShow = () => {
    props.setShow(!props.show);
  };
  return (
    <>
    {props.show ?    <div onClick={toggleShow} className={scss["backdrop"]}>
            
            </div> : null}
    </>
  );
};

export default Backdrop;
