import React from "react";
import { connect,useDispatch } from "react-redux";
import { toggleLoading } from "Redux/actions/loadingActions";

import scss from "./creationToolbox.module.scss";

//components

const CreationToolbox = (props) => {
  const { modalKeys, toggleModals, closeAll, toggleConfirm } = props;
  const dispatch = useDispatch()
  return (
    <div className="creation-toolbox">
      {modalKeys.map((modal) => {
        let name = modal.text.toLowerCase();
        return (
          <div
            id={name}
            key={modal.id}
            onClick={toggleModals}
            className={`${name} tool project-${name}-information`}
          >
            <h3 className="tool-text">{modal.text}</h3>
          </div>
        );
      })}
      {props.armadillo.tableLocations.tables.length > 0 ? (
        <div onClick={() => dispatch(toggleLoading())} className={scss["results"]}>
          <h3 className={scss["tool-text"]}>Results</h3>
        </div>
      ) : null}

      <div className="buttons-vertical">
        <button onClick={closeAll} className="submit-land">
          Close all
        </button>

        <button className="creation-next" onClick={toggleConfirm}>
          Next
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    project: state.project,
    armadillo: state.armadillo,
  };
};

export default connect(mapStateToProps)(CreationToolbox);
