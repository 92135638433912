import React from "react";
import { inverter } from "data/inverter";
import { connect } from "react-redux";

//components
import InverterSearch from "components/inverters/InverterSearch";

const ElectricDataModal = (props) => {

  



  return (
    <div className="electric-data-modal creation-modal">
      <div className="modules-and-inverters">
        <InverterSearch inv={inverter} />
      </div>

      <div className="buttons">
        <button className="electrical" onClick={(e) => props.toggleModals(e)}>
          Close
        </button>
        <button
          className="electrical save-and-close"
          onClick={(e) => props.toggleModals(e)}
        >
          Save and Close
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  };
};

export default connect(mapStateToProps)(ElectricDataModal);
