/* eslint-disable */
import { jsPDF } from "jspdf";

export const getResultsPdf = (data) => {
    const doc = new jsPDF();
    
    const project = data.project_data
    delete project.SATRowInstances
    delete project.SATRowStructural
    delete project.InverterInstances
    delete project.project_id
    delete project.sat_row_id
    const details = project.ProjectData
    const date = Date.now()
    const formatted = new Intl.DateTimeFormat('en-US').format(date)
   

    const content = JSON.stringify(project,null,'\t').replace(/[\{\},\"\'\ ]+/g, "")
    
    doc.text(content, 0,5);
    doc.setFontSize(10)
    doc.save(`${details.project_name} Results.pdf`);
}

// Default export is a4 paper, portrait, using millimeters for units
