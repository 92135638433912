import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import scss from "./createNew.module.scss";
import ModuleSearch from "components/modules/ModuleSearch";
import {
  saveSatRowCfg,
  setSATRowCfg,
  updateSavedCfg,
  toggleOverwriteWarning,
} from "Redux/actions/ArmadilloActions";
import { clearDisplayedModules } from "Redux/actions/electrical/electricalData";
import { getTrackerDimensions } from "Redux/actions/armadillo/getTrackerDimensions";
import { errorMessage } from "Redux/actions/notificationActions";
import Modal from "components/UI/Modal";

const CreateNew = (props) => {
  const { setCfg, satRowCfg, simpleChangeHandler } = props;
  const [trackeris2p, setTrackeris2p] = useState(false);
  const [overridingDimensions, setOverridingDimensions] = useState(false);

  const dispatch = useDispatch();

  const toggle2pConfig = (boolean) => {
    setTrackeris2p(boolean);
  };
  const toggleDimensionOverride = (e) => {
    e.preventDefault();
    setOverridingDimensions(!overridingDimensions);
  };

  const configHandler = (e) => {
    setCfg({
      ...satRowCfg,
      [e.target.name]: e.target.valueAsNumber || e.target.value,
    });
    if (satRowCfg.is2p === "true") {
      toggle2pConfig(false);
    } else {
      toggle2pConfig(true);
    }
  };

  const submitHandler = (e) => {
    console.log(props.selectedModule)
    e.preventDefault();
    if (satRowCfg.sat_table_name) {
     
      dispatch(saveSatRowCfg(satRowCfg, props.user));
      dispatch(clearDisplayedModules());
    } else if (props.selectedModule === undefined) {
      errorMessage("You must select a PV module to continue.");
    } else {
      
      errorMessage("You must give the table a unique name to continue.");
    }
  };
  const overwriteHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const savedIndex = props.saved.findIndex(
      (i) => i.tableCfg.sat_table_name === satRowCfg.sat_table_name,
    );
    const table = { ...props.saved[savedIndex] };
    table.tableCfg = satRowCfg;
    const id = props.saved[savedIndex].id;
    const users_id = props.saved[savedIndex].users_id;
    const sender = { table: table, id: id, users_id: users_id };
    dispatch(updateSavedCfg(sender));
    dispatch(toggleOverwriteWarning(false));
  };

  const trackerChangeHandler = (e) => {
    dispatch(getTrackerDimensions(props.selectedModule, satRowCfg));
  };

  useEffect(() => {
    let copy = { ...props.armadillo };
    setCfg(copy);
    //eslint-disable-next-line
  }, [
    props.armadillo.string_length,
    props.armadillo.strings_per_table,
    props.armadillo.module_gap_ew,
    props.armadillo.is2p,
  ]);

  useEffect(() => {
    dispatch(getTrackerDimensions(props.selectedModule, satRowCfg));
    //eslint-disable-next-line
  }, [trackeris2p]);

  useEffect(() => {
    if (props.armadillo.is2p === "true") {
      setTrackeris2p(true);
    }
    if (props.armadillo.is2p === "false") {
      setTrackeris2p(false);
    }
    if (props.selectedTable !== undefined) {
      dispatch(setSATRowCfg(props.selectedTable.tableCfg));
    }
    //eslint-disable-next-line
  }, []);

  return (
    <form className={scss["tracker-cfg-form"]}>
      {props.overwriteWarning ? (
        <Modal
          title={`Overwrite ${satRowCfg.sat_table_name}`}
          text={`This will overwrite ${satRowCfg.sat_table_name}. Do you want to proceed?`}
          specialButton={`Yes, overwrite it.`}
          specialFunction={(e) => overwriteHandler(e)}
          toggleModal={() => dispatch(toggleOverwriteWarning(false))}
        />
      ) : null}

      <div className={scss["sat-row-inputs"]}>
        <div className={scss["input-container"]}>
          <label htmlFor="table_name">Table Name</label>
          <input
            type="text"
            placeholder={
              satRowCfg.sat_table_name === ""
                ? "enter table name"
                : satRowCfg.sat_table_name
            }
            name="sat_table_name"
            value={satRowCfg.sat_table_name}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
          />
        </div>
        <ModuleSearch />
        <div className={scss["input-container"]}>
          <label htmlFor="string_length">String Length</label>
          <input
            type="number"
            name="string_length"
            value={satRowCfg.string_length}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="strings_per_table">Strings Per Table</label>
          <input
            type="number"
            name="strings_per_table"
            value={satRowCfg.strings_per_table}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="drive_gap">Drive Gap (m)</label>
          <input
            type="number"
            name="drive_gap"
            value={satRowCfg.drive_gap}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="bearing_gap">Bearing Gap (m)</label>
          <input
            type="number"
            name="bearing_gap"
            value={satRowCfg.bearing_gap}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="torque_tube_overhang">Torque Tube Overhang (m)</label>
          <input
            type="number"
            name="torque_tube_overhang"
            value={satRowCfg.torque_tube_overhang}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="is2p">2p Configuration</label>
          <div className={scss["twop-radio-container"]}>
            <div className={scss["twop-radio"]}>
              <p className={scss["radio-label"]}>Yes</p>
              <input
                type="radio"
                name="is2p"
                value={true}
                checked={satRowCfg.is2p === "true"}
                // onChange={trackerChangeHandler}
                onChange={configHandler}
              />
            </div>
            <div className={scss["twop-radio"]}>
              <p className={scss["radio-label"]}>No</p>
              <input
                type="radio"
                name="is2p"
                value={false}
                checked={satRowCfg.is2p !== "true"}
                // onChange={trackerChangeHandler}
                onChange={configHandler}
              />
            </div>
          </div>
        </div>

        {trackeris2p ? (
          <div className="twoP-inputs">
            <div className={scss["input-container"]}>
              <label htmlFor="module_gap_ew">EW Module Gap (m)</label>
              <input
                type="number"
                placeholder={satRowCfg.module_gap_ew}
                name="module_gap_ew"
                value={parseFloat(satRowCfg.module_gap_ew)}
                onChange={simpleChangeHandler}
                onBlur={trackerChangeHandler}
              />
            </div>
          </div>
        ) : null}

        <div className={scss["input-container"]}>
          <label htmlFor="module_gap_ns">NS Module Gap (m)</label>
          <input
            type="number"
            placeholder={satRowCfg.module_gap_ns}
            name="module_gap_ns"
            value={parseFloat(satRowCfg.module_gap_ns)}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="number_of_piles">Number of piles</label>
          <input
            type="number"
            placeholder={satRowCfg.n_piles}
            name="n_piles"
            value={satRowCfg.n_piles}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
          />
        </div>
      </div>

      <div className={scss["sat-row-dimensions"]}>
        <h4 className={scss["section-heading"]}>SAT Row Dimensions</h4>
        <div className={scss["input-container"]}>
          <label htmlFor="sat_row_length">SAT row length (m)</label>
          <input
            type="number"
            placeholder={satRowCfg.sat_row_length}
            name="sat_row_length"
            value={parseFloat(satRowCfg.sat_row_length.toFixed(2))}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
            disabled={!overridingDimensions}
          />
        </div>
        <div className={scss["input-container"]}>
          <label htmlFor="sat_row_width">SAT row width (m)</label>
          <input
            type="number"
            placeholder={satRowCfg.sat_row_width}
            name="sat_row_width"
            value={parseFloat(satRowCfg.sat_row_width.toFixed(2))}
            onChange={simpleChangeHandler}
            onBlur={trackerChangeHandler}
            disabled={!overridingDimensions}
          />
        </div>
        <div className={scss["input-container"]}>
          <label htmlFor="sat_row_dc_power">SAT row DC power</label>
          <input
            type="number"
            placeholder={parseFloat(satRowCfg.sat_row_dc_power.toFixed(2))}
            name="sat_row_dc_power"
            value={parseFloat(satRowCfg.sat_row_dc_power.toFixed(2))}
            onChange={simpleChangeHandler}
            disabled
          />
        </div>
        <button
          className={scss["edit-dimensions"]}
          onClick={(e) => toggleDimensionOverride(e)}
        >
          Edit dimensions
        </button>
      </div>
      <button
        className={scss["save-new-cfg"]}
        onClick={(e) => submitHandler(e)}
      >
        Save This Configuration
      </button>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
    manufacturers: state.electrical.manufacturers,
    selectedModule: state.electrical.selectedModule,
    overwriteWarning: state.tables.overwriteWarning,
    selectedTable: state.tables.selectedTable,
    saved: state.user.savedSatRowCfgs,
  };
};

export default connect(mapStateToProps)(CreateNew);
