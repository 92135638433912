const initialState = {
  rotate: true
    
  };
  
  const tablesReducer = (state = initialState, action) => {
    switch (action.type) {
     case "TOGGLE_ROTATION":
         return {
             ...state,rotate: !state.rotate
         }
    
      default:
        return state;
    }
  };
  
  export default tablesReducer;
  