import axios from "axios";
import utmObj from "utm-latlng";
import { geoJsonToKml } from './geoJsonToKml'
let utm = new utmObj("WGS 84");

export const layoutCall =  (
  allAreas,
  sat_row_width,
  sat_row_length,
  n_piles,
  gap_ns,
  gap_ew,
  project_data,
  rotate,
  user
) => async (dispatch) => {
    let tableResult = [];
    let pileResult = [];
    let motorPileResult = [];
    let totalLandAreas = Object.values(allAreas).length
    // let updatedAreas = []
  
    //this will track the total area of the site in square meters.
  for (let area in allAreas) {
    let current = allAreas[area];
   
    let sendMe = {
      allAreas: [current],
      sat_row_length: sat_row_length,
      sat_row_width: sat_row_width,
      n_piles: n_piles,
      gap_ns: gap_ns,
      gap_ew: gap_ew,
      project_data: project_data,
      rotate: rotate
    };
    let results = await axios.post(
      `${process.env.REACT_APP_BACKEND}/layout/generate`,
      sendMe,
    );
    try {
      
      let response = results.data[0]
     
      let tablesToRender = [];
      let pilePointsToRender = [];
      let motorPilesToQuery = [];
      
  
    if(response.properties.areaId === totalLandAreas) {
      dispatch({
        type: "SET_STATUS_MESSAGE",
        payload: `All areas processed!`
      })
    }else {
      dispatch({
        type: "SET_STATUS_MESSAGE",
        payload: `Processing area ${response.properties.areaId} of ${totalLandAreas}`
      })
    }
    
     
      for (let table of response.properties.tablesInArea) {
        let tableInLatLng = [];

        //CONVERT TABLE COORDINATES FROM UTM TO LAT LNG
        for (let coords of table.geometry.coordinates[0]) {
          let latLng = utm.convertUtmToLatLng(
            coords[1],
            coords[0],
            table.properties.origin.ZoneNumber,
            table.properties.origin.ZoneLetter,
          );

          tableInLatLng.push([latLng.lng, latLng.lat]);
        }
        //CONVERT TABLE COORDINATES FROM UTM TO LAT LNG

        table.geometry.coordinates[0] = tableInLatLng;
        //set the new latLng coords as the table coords.

        tablesToRender.push(table);
        //push the modified table on to the tablesToRender array
      }
      for (let pilePoint of response.properties.pilePoints) {
        //again follow a similar process for the pilePoints of the region
        let latLng = utm.convertUtmToLatLng(
          pilePoint.geometry.coordinates[0],
          pilePoint.geometry.coordinates[1],
          current.properties.origin.ZoneNumber,
          current.properties.origin.ZoneLetter,
        );
        pilePoint.geometry.coordinates = [latLng.lng, latLng.lat];
        //make the conversion and push the point feature onto the pilePointsToRender array.
        pilePointsToRender.push(pilePoint);
      }
      for (let motorPile of response.properties.motorPiles) {
        //again follow a similar process for the motorPiles of the region
        let latLng = utm.convertUtmToLatLng(
          motorPile.geometry.coordinates[1],
          motorPile.geometry.coordinates[0],
          current.properties.origin.ZoneNumber,
          current.properties.origin.ZoneLetter,
        );
        motorPile.geometry.coordinates = [latLng.lng, latLng.lat];
        //make the conversion and push the point feature onto the motorPilesToRender array.
        motorPilesToQuery.push(motorPile);
      }
     
      if(allAreas[area].properties.areaId === response.properties.areaId) {
        allAreas[area] = response
      }
      tableResult = [...tableResult, ...tablesToRender];
      pileResult = [...pileResult, ...pilePointsToRender];
      motorPileResult = [...motorPileResult, ...motorPilesToQuery];
    } catch (err) {
      console.trace(err);
    }
   
    
  
    dispatch({
        type: "SIMPLE_LAYOUT",
        payload: { tables: tableResult },
        pileLocations: { piles: pileResult },
        motorPiles: motorPileResult,
      });
   
      
  }

  console.log(allAreas)
  dispatch({
    type: "UPDATE_AREAS",
    payload: allAreas
  })

  geoJsonToKml(allAreas,project_data,user)
 
};
