const initialState = {
  showLoading: false,
  viewing: false,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING_TRUE":
      return {
        ...state,
        showLoading: true,
      };
    case "SET_LOADING_FALSE":
      return {
        ...state,
        showLoading: false,
      };
    case "TOGGLE_LOADING":
      return {
        ...state,
        showLoading: !state.showLoading,
      };
      case "SET_VIEWING_TRUE":
      return {
        ...state,
        viewing: true,
      };
    case "SET_VIEWING_FALSE":
      return {
        ...state,
        viewing: false,
      };

    default:
      return state;
  }
};

export default projectReducer;
