import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { validateUser, logout } from "Redux/actions/authenticationActions";
import { getUserProjects } from "Redux/actions/ArmadilloActions";
import { fetchSatRowCfgs } from "Redux/actions/userActions";
import { resetState } from "Redux/actions/armadillo/resetState";
import { setViewingFalse } from "Redux/actions/loadingActions";

//components
import ProjectsComponent from "./projects/ProjectsComponent";
import EditCfgs from "../../modals/EditCfgs/EditCfgs";
import Backdrop from "components/UI/Backdrop/Backdrop";
//styles
import scss from "./userDashboard.module.scss";
import { setSelectedInverter, setSelectedModule } from "Redux/actions/electrical/electricalData";

const UserDashboard = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  let user = props.user.userData;
  let projects = props.user.projects;
  let projectsArr = Object.values(projects);

  const [isEditingTrackers, setEditingTrackers] = useState(false);
  const handleEditing = () => {
    setEditingTrackers(!isEditingTrackers);
  };

  const goToCreation = () => {
    if (user.username) {
      history.push(`/${user.username}/createproject`);
    }
  };

  const userLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    let copy = { ...props.armadillo };
    dispatch(resetState(copy));
    dispatch(setSelectedModule(undefined))
    dispatch(setSelectedInverter(undefined))
    dispatch(validateUser());
    dispatch(setViewingFalse());

    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (user.id) {
      dispatch(fetchSatRowCfgs(user));
      if (projectsArr.length < 1) {
        dispatch(getUserProjects(user));
      }
    }

    //eslint-disable-next-line
  }, [user]);

  



  return (
    <div className={scss["user-dashboard"]}>
      <Backdrop show={isEditingTrackers} setShow={setEditingTrackers} />
      <h1 className={scss["welcome-text"]}>
        {projectsArr?.length > 0
          ? `Welcome back, ${user.username}`
          : `Welcome, ${user.username}`}
      </h1>
      {props.savedSatRows.length ? (
        <button onClick={handleEditing}>Edit Trackers</button>
      ) : (
        <button onClick={handleEditing}>Configure Tracker</button>
      )}
      {isEditingTrackers ? <EditCfgs closeModal={setEditingTrackers} /> : null}
      <div className={scss["create-user-project"]}>
        <div className={scss["create-project-component"]}>
          <h1 className={scss["project-heading"]}>
            {projectsArr.length > 0
              ? `Create a new project`
              : `Create your first project`}
          </h1>

          <button
            className={scss["create-project-button"]}
            onClick={goToCreation}
          >
            Create Project
          </button>
        </div>
        {projects ? <ProjectsComponent projects={projects} /> : null}
      </div>
      <button className={scss["logout"]} onClick={userLogout}>
        logout
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    savedSatRows: state.user.savedSatRowCfgs,
    armadillo: state.armadillo,
  };
};

export default connect(mapStateToProps)(UserDashboard);
