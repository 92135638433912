import React from "react";
import scss from './standardModal.module.scss'

const Modal = (props) => {
  return (
    <div className={scss["modal-container"]}>
      <h4 className={scss["modal-title"]}>{props.title}</h4>
      <p className={scss["modal-text"]}>{props.text}</p>
      {props.form}
      <div className={scss["modal-buttons"]}>
        {props.specialButton ? (
          <button
            className={scss["special-modal-button"]}
            onClick={props.specialFunction}
          >
            {props.specialButton}
          </button>
        ) : null}
        <button className={scss["modal-close-button"]} onClick={props.toggleModal}>
          close
        </button>
      </div>

      {props.content}
    </div>
  );
};

export default Modal;
