const initialState = {
  userData: {
  },
  directory: "",
  userIP: "",
  projects: [],
  savedSatRowCfgs: [],
};

const tablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload
      };

      case "SET_USER_PROJECTS":
      return {
        ...state,
        projects: action.payload
      };
      case "FETCH_SAVED_SATROWS":
        return {
          ...state,
          savedSatRowCfgs: action.payload,
        };
      case "GET_USER_IP":
        return {
          ...state,userIP: action.payload
        }
        case "GET_USER_DIRECTORY":
        return {
          ...state,directory: action.payload
        }
    case "ADD_PROJECT" :
      return  {
        ...state,
        projects: action.payload
      }
    case "SEND_LOGIN_CREDENTIALS":
      return {...state,userData: action.payload}
   case "SEND_REGISTRATION_CREDENTIALS": {
     return  {
       ...state,userData: action.payload
     }
   }

   case "CLEAR_USER_DATA":
     return {
       ...state,userData: {},
       projects: {},
       tables: {},
       userIP: ""
     }
    default:
      return state;
  }
};

export default tablesReducer;
