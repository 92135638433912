import React, { useState } from "react";

import scss from "./passwordReset.module.scss";
import {resetPassword} from 'Redux/actions/authenticationActions';

const PasswordReset = (props) => {
 
  const [password, setNewPassword] = useState("")
  const [confirm_password, setConfirmPassword] = useState("")
  const changeHandler = (e) => {
    setNewPassword(e.target.value)
  }
  const confirmHandler = (e) => {
    setConfirmPassword(e.target.value)
  }

  const submitHandler = (e) => {
    const token= window.location.href.split("/")[4]
    console.log(window.location.href.split("/"))
    e.preventDefault()
    resetPassword(password,token)
    

  }
  return (
    <div className={scss["password-reset-container"]}>
      <h4 className={scss["section-heading"]}>Choose your new password</h4>
      <form onSubmit={submitHandler} className={scss["reset-form"]}>

        <div className={scss["input-container"]}>
          <label htmlFor={scss["new-password"]}>New password</label>
          <input
            type="text"
            value={password}
            name="new-password"
            className={scss["reset-input"]}
            onChange={changeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor={scss["new-password"]}>Confirm password</label>
          <input
            type="text"
            value={confirm_password}
            name="new-password"
            className={scss["reset-input"]}
            onChange={confirmHandler}
          />
        </div>

        
        <div className={scss["submit-container"]}>
          <button className={scss["submit"]}>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default PasswordReset;
