import utmObj from "utm-latlng";
let utm = new utmObj("WGS 84");
export const getPilePoints = async (
  motorPiles,
  tableWidth,
  tableLength,
  n_piles,
  origin,
) => {
 

  const motorPileRank = n_piles / 2 + 0.5;
  const interiorSpacing = tableLength / (n_piles - 0.5);
  const exteriorSpacing = interiorSpacing / 3;
  // const torqueTubeOverhang = 0.5;
  // const torqueTubeLength = tableLength + 2 * torqueTubeOverhang;
  let pileOffsets = [];
  let allPiles = [];
  for (let i = 1; i <= n_piles; i++) {
    let y = (motorPileRank - i) * interiorSpacing;
    let x = 0;

    pileOffsets.push([x, y]);
  }
  let endOfArray = pileOffsets.length - 1;
  //add and subtract half os the exterior spacing from
  //first and last index of pileOffsets
  pileOffsets[0] = [pileOffsets[0][0], pileOffsets[0][1] - exteriorSpacing / 2];
  pileOffsets[endOfArray] = [
    pileOffsets[endOfArray][0],
    pileOffsets[endOfArray][1] + exteriorSpacing / 2,
  ];

  for (let motorPile of motorPiles) {
    for (let offset of pileOffsets) {
      let index = pileOffsets.indexOf(offset);
      let latLng = utm.convertUtmToLatLng(
        motorPile.geometry.coordinates[0] ,
        motorPile.geometry.coordinates[1] + offset[1],
        origin.ZoneNumber,
        origin.ZoneLetter,
      );

      allPiles.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [latLng.lng, latLng.lat],
        },
        properties: {
          radius: "1m",
          rank: index,
          type: index === motorPileRank ? "motor" : "bearing",
        },
      });
    }
  }



  return allPiles;
};
