import KML from "tokml";
import { uploadGeneratedFiles } from "../userActions";


export const geoJsonToKml = async (geoJson,project_data, user) => {
  let allTables = [];
  //   {
  //     "type": "GeometryCollection",
  //     "properties": {
  //         "value": "foo"
  //     },
  //     "geometries": [{
  //         "type": "Point",
  //         "coordinates": [0, 0]
  //     }, {
  //         "type": "Polygon",
  //         "coordinates": [[[45, 45], [45, -45], [-45, -45], [-45, 45], [45,45]]]
  //     }]
  // }
  for (let area in geoJson) {
    let current = geoJson[area];
    // let newFeature = {
    //   type:"Feature",
    //   geometry: current.geometry,
    //   properties: {
    //     origin: current.properties.origin,
    //     color: [0,0,0,0],
    //     outer: true,

    //   }
    // }
    

    // allTables.push(newFeature)
    allTables.push(...current.properties.tablesInArea);
  }
  let featureCollection = {
    type: "FeatureCollection",
    properties:  {
      result: true
    },
    features: allTables,
  };
 

  
 

 if(featureCollection.features.length) {
  let kml =  KML(featureCollection);
  let projectName = project_data.project_name
  console.log(projectName)

  let tableData = new File([kml], `${projectName}_Results.kml`, {
    type: "KML",
  });
 
  uploadGeneratedFiles(user,project_data,[tableData])

 
 }

};
