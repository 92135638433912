import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import ModuleSelect from "./ModuleSelect";
import SelectedModules from "./SelectedModules";
import {
  getModulesByManufacturer,
  clearSelectedManufacturer,
  clearDisplayedModules,
} from "Redux/actions/electrical/electricalData";

//styles
import scss from "./moduleSearch.module.scss";

const ModuleSearch = (props) => {
  const [displayedModules, setDisplayedModules] = useState([]);

  const dispatch = useDispatch();

  const clickHandler = (e) => {
    console.log(e.target.value)
    dispatch(getModulesByManufacturer(e.target.value));
  };

  const resetString = () => {
    setDisplayedModules([]);
    dispatch(clearDisplayedModules());
    dispatch(clearSelectedManufacturer());
  };

  const clearSelection = (e) => {
    e.preventDefault();
    dispatch(clearSelectedManufacturer());
    dispatch(clearDisplayedModules());
    setDisplayedModules([]);
  };

  useEffect(() => {
  
      setDisplayedModules(props.displayedModules);
    
  }, [props.displayedModules, props.selectedManufacturer]);

  return (
    <div className={scss["module-search"]}>
      <h1 className={scss["title"]}>Select your PV modules</h1>
      
      <SelectedModules />
      <select
        onClick={(e) => clickHandler(e)}
        name="manufacturers"
        className={scss["manufacturer-select"]}
      >


        <option value={props.selectedModule ? props.selectedModule.manufacturer.id : "Select One"} hidden>{props.selectedModule ? props.selectedModule.manufacturer : "Select One"}</option>
        {props.manufacturers.map((manufacturer) => {
          
          return (
            <option key={manufacturer.id} value={manufacturer.id}>
              {manufacturer.name}
            </option>
          );
        })}
      </select>
      <button onClick={clearSelection} className="cancel-selection">
        Cancel Selection
      </button>

      {displayedModules.length > 0 ? (
        <ModuleSelect
          displayedModules={displayedModules}
          resetString={resetString}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    modules: state.electrical.modules,
    manufacturers: state.electrical.manufacturers,
    selectedManufacturer: state.electrical.selectedManufacturer,
    selectedModule: state.electrical.selectedModule,
    displayedModules: state.electrical.displayedModules,
  };
};
export default connect(mapStateToProps)(ModuleSearch);
