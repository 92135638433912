import React, { useState } from "react";

const Module = (props) => {
  const [showInfoTip, setInfoTip] = useState(false);
  const [showAddTip, setAddTip] = useState(false);
  const infoTipHandler = () => {
    setInfoTip(!showInfoTip);
  };
  const addTipHandler = () => {
    setAddTip(!showAddTip);
  };
  return (
    <div className="individual-module" key={props.data.id}>
      <p className="name">{props.data.name}</p>
      <div className="module-buttons">
        <div
          className="button"
          onMouseEnter={infoTipHandler}
          onMouseLeave={infoTipHandler}
        >
          <i className="far fa-question-circle"> </i>
          {showInfoTip ? (
            <span className="tooltip info-tip">Get Info</span>
          ) : null}
        </div>
        <div
          className="button"
          onMouseEnter={addTipHandler}
          onClick={() => props.function(props.data)}
          onMouseLeave={addTipHandler}
        >
          <i className="far fa-plus-square"></i>
          {showAddTip ? (
            <span className="tooltip add-tip">Add to project</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Module;
