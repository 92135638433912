import React, { useState,useEffect } from "react";
import { connect,useDispatch } from "react-redux";
import scss from "./loadingModal.module.scss";
import { setLoadingFalse } from "Redux/actions/loadingActions";
import { downloadProjectFiles } from "Redux/actions/userActions";



//components

import ArrayAreaBreakdown from "./ArrayAreaBreakdown";

const LoadingModal = (props) => {
  //get connected to store
  //write function to update message
  let landArray = Object.values(props.arrayAreas);

  const [powerTotal, setPowerTotal] = useState(0);
  const [minInverters, setMinInverters] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setPowerTotal((props.cfg.sat_row_dc_power * props.tables.length) / 1000000)
    setMinInverters(Math.round(powerTotal / props.dcAcRatio))

  //eslint-disable-next-line
  }, [props.message])

  return (
    <div className={scss["loading-modal"]}>
      <p className={scss["status-text"]}>{props.message}</p>

      <div className={scss["land-data"]}>
        <h4 className={scss["title"]}>Land Data</h4>
        <p className={scss["total-area"]}>
          Total Area: {props.totalArea.toLocaleString()} Acres
        </p>

        {props.keepoutsArea ? (
          <p className={scss["buildable-area"]}>
            Buildable Area:{" "}
            {(props.totalArea - props.keepoutsArea).toLocaleString()} Acres
          </p>
        ) : null}
      </div>

      <div className={scss["electrical-data"]}>
        <h5 className={scss["subtitle"]}>Selected Module</h5>
        <p className={scss["selected-module"]}>
          {props.module ? props.module.name : props.savedModule}
        </p>
        <h5 className={scss["subtitle"]}>Selected Inverter</h5>
        <p className={scss["selected-inverter"]}>
          {props.inverter ? props.inverter.name : props.savedInverter}
        </p>
      </div>
      <div className={scss["sat-row-overview"]}>
        <h4 className={scss["title"]}>SAT Row Overview</h4>
        <p className={scss["total-tables"]}>
          {props.cfg.sat_table_name} x {props.tables.length.toLocaleString()}
        </p>
        <p className={scss["power-subtotal"]}>
          Power running total: {powerTotal.toLocaleString()} MW
        </p>
        <p className={scss["minimum-inverters"]}>
         Minimum Inverters: {minInverters}
        </p>
      </div>
      {landArray.length ? <ArrayAreaBreakdown /> : null}

      <button onClick={()=>dispatch(downloadProjectFiles(props.projectData.project_id))} className={scss["download-project-files"]}>Download Project</button>

      <button
        onClick={() => dispatch(setLoadingFalse())}
        className={scss["close-loading-modal"]}
      >
        close
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.armadillo.statusMessage,
    tables: state.armadillo.tableLocations.tables,
    totalArea: state.armadillo.totalArea,
    keepoutsArea: state.armadillo.keepoutsArea,
    cfg: state.armadillo.SATRowCfg,
    arrayAreas: state.armadillo.updatedAreas,
    savedInverter: state.armadillo.ProjectData.inverter_name,
    savedModule: state.armadillo.ProjectData.module_name,
    inverter: state.electrical.selectedInverter,
    module: state.electrical.selectedModule,
    dcAcRatio: state.electrical.targetDcAcRatio,
    projectData: state.armadillo.ProjectData
  };
};
export default connect(mapStateToProps)(LoadingModal);
