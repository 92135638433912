import React from 'react';
import {connect} from 'react-redux';


const SelectedModules = (props) => {
  
    return (
        <div>
         {props.selectedInverter !== undefined ?  <div className="selected-inverter">
            
            <p className="name">{props.selectedInverter.name}</p>
          </div> : null}
          
            
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      selectedInverter: state.electrical.selectedInverter,
    };
  };

export default connect(mapStateToProps)(SelectedModules);
