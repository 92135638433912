import React, { useState } from "react";
import { connect,useDispatch } from "react-redux";
import { updateProjectState } from "Redux/actions/ArmadilloActions";

//styles
import scss from "./structuralModal.module.scss";

const StructuralModal = (props) => {
  const dispatch = useDispatch();
  const [structuralData, setStructuralData] = useState({
    modules_high: props.armadillo.modules_high,
    ns_slope_tol_1: props.armadillo.ns_slope_tol_1,
    ns_slope_tol_2: props.armadillo.ns_slope_tol_2,
    ew_slope_tol_1: props.armadillo.ew_slope_tol_1,
    ew_slope_tol_2: props.armadillo.ew_slope_tol_2,
    min_reveal: props.armadillo.min_reveal,
    max_reveal: props.armadillo.max_reveal,
  });

  const changeHandler = (e) => {
    setStructuralData({
      ...structuralData,
      [e.target.name]: e.target.valueAsNumver || e.target.value,
    });
  };
  
  const submitHandler = (e) => {
    dispatch(updateProjectState(structuralData,props.armadillo))
    props.toggleModals(e, structuralData)
  }
  return (
    <div className={scss["structural-data-modal"]}>
      <h4 className={scss["section-title"]}>Elevation Data</h4>
      <div className={scss["tracker-elevation-data"]}>
        <div className={scss["input-container"]}>
          <label htmlFor="min_reveal">Minimum Pile reveal</label>
          <input
            type="number"
            className={scss["tracker-input min-reveal"]}
            placeholder={structuralData.min_reveal}
            name="min_reveal"
            value={structuralData.min_reveal}
            onChange={changeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="height">Maximum Pile reveal</label>
          <input
            type="number"
            className={scss["tracker-input max-reveal"]}
            placeholder={structuralData.max_reveal}
            name="max_reveal"
            value={structuralData.max_reveal}
            onChange={changeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="ns_slope_tol_2">Maximum Slope tolerance</label>
          <label htmlFor="ns_slope_tol_2">(along torque tube axis)</label>
          <input
            type="number"
            className={scss["tracker-input max-reveal"]}
            placeholder={structuralData.ns_slope_tol_2}
            name="ns_slope_tol_2"
            value={structuralData.ns_slope_tol_2}
            onChange={changeHandler}
          />
        </div>

        <div className={scss["input-container"]}>
          <label htmlFor="ew_slope_tol_2">Maximum Slope tolerance</label>
          <label htmlFor="ew_slope_tol_2">(between rows)</label>
          <input
            type="number"
            className={scss["tracker-input max-reveal"]}
            placeholder={structuralData.ew_slope_tol_2}
            name="ew_slope_tol_2"
            value={structuralData.ew_slope_tol_2}
            onChange={changeHandler}
          />
        </div>
      </div>
      <div className="buttons">
        <button className="structural" onClick={props.toggleModals}>
          Close
        </button>
        <button
          className="structural"
          onClick={(e) => submitHandler(e)}
        >
          Save and Close
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    armadillo: state.armadillo.SATRowStructural,
  };
};

export default connect(mapStateToProps)(StructuralModal);
