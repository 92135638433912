/* eslint-disable */
import publicIp from "public-ip";
import axios from "axios";
import { toast } from "react-toastify";
import utmObj from "utm-latlng";
import { getPilePoints } from "./armadillo/findPilePoints";
import areaOf from "@turf/area";
import { errorMessage } from "./notificationActions";
import { getProjectId } from "./ArmadilloActions";
import { setLoadingTrue } from "./loadingActions";
import { setViewingTrue } from "Redux/actions/loadingActions";
import { getResultsPdf } from "./armadillo/pdfExport";
import { getInverterById, getModuleById } from "./electrical/electricalData";

let utm = new utmObj("WGS 84");
export const setName = (name) => (dispatch) => {
  return dispatch({
    type: "SET_PROJECT_NAME",
    payload: name,
  });
};

export const getUserIp = () => (dispatch) => {
  publicIp
    .v4()
    .then((res) => {
      dispatch({
        type: "GET_USER_IP",
        payload: res,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const fetchSatRowCfgs = (user) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_BACKEND}/tables/getTable/${user.id}`)
    .then((res) => {
      for (let cfg of res.data.savedCfg) {
        cfg.tableCfg.id = cfg.id;
      }

      dispatch({
        type: "FETCH_SAVED_SATROWS",
        payload: res.data.savedCfg,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadKMZFile = (user, state, file) => async (dispatch) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("project_name", state.project_name);
  formData.append("owner_id", user.id);
  formData.append("boxFolderId", user.boxFolderId);

  axios
    .post(`${process.env.REACT_APP_BACKEND}/uploads/upload`, formData)
    .then((res) => {
      console.log(res.data.result)
      dispatch(getProjectId(res.data.project_id));
      const projectLand = res.data.result.projectLand.map((item) => item);
      let keepoutsToRender = [];
      let totalArea = 0;
      let keepoutsArea = 0;
      if (res.data.result.keepouts) {
        const keepouts = res.data.result.keepouts.map((item) => item);

        keepouts?.map((keepout) => {
          let newCoords = [];
          keepout.geometry.coordinates.map((item) => {
            item.map((temp) => {
              let latLng = utm.convertUtmToLatLng(
                temp[1],
                temp[0],
                keepout.properties.origin.ZoneNumber,
                keepout.properties.origin.ZoneLetter,
              );
              return newCoords.push([latLng.lng, latLng.lat]);
            });
          });
          let copy = { ...keepout };

          copy.geometry.coordinates = [newCoords];
          keepoutsToRender.push(copy);
        });
      }

      let sitePolygon = res.data.result.siteBoundingBox.sitePoly;
      let polyOrigin =
        res.data.result.siteBoundingBox.sitePoly.properties.origin;
      let center =
        res.data.result.siteBoundingBox.sitePoly.properties.center.geometry
          .coordinates;
      let centerPoint = utm.convertUtmToLatLng(
        center[1],
        center[0],
        polyOrigin.ZoneNumber,
        polyOrigin.ZoneLetter,
      );

      let newBboxCoords = [];
      sitePolygon.geometry.coordinates.map((item) => {
        item.map((temp) => {
          let latLng = utm.convertUtmToLatLng(
            temp[1],
            temp[0],
            polyOrigin.ZoneNumber,
            polyOrigin.ZoneLetter,
          );
          newBboxCoords.push([latLng.lng, latLng.lat]);
        });
      });
      let tempCopy = { ...sitePolygon };
      tempCopy.geometry.coordinates = [newBboxCoords];
      tempCopy.properties.center = centerPoint;

      //eslint-disable-next-line
      let projectLandToRender = [];

      //convert UTM to latlng to render on map

      for (let land of projectLand) {
        let newCoords = [];
        land.geometry.coordinates.map((item) => {
          item.map((temp) => {
            let latLng = utm.convertUtmToLatLng(
              temp[1],
              temp[0],
              land.properties.origin.ZoneNumber,
              land.properties.origin.ZoneLetter,
            );
            newCoords.push([latLng.lng, latLng.lat]);
          });
        });

        let copy = { ...land };

        copy.geometry.coordinates = [newCoords];
        copy.properties.area = areaOf(copy);
        land.properties.area = areaOf(copy);
        totalArea += land.properties.area;

        projectLandToRender.push(copy);
      }
      for (let keepOut of keepoutsToRender) {
        keepOut.properties.area = areaOf(keepOut);
        keepoutsArea += keepOut.properties.area;
      }
      let projectLandAreas = {};
      for (let i = 0; i < res.data.result.projectLand.length; i++) {
        projectLandAreas[i] = res.data.result.projectLand[i];
      }

      dispatch({
        type: "GET_LAND_AREAS",
        landFile: res.data.landFile,
        payload: projectLandAreas,
        keepouts: { keepouts: keepoutsToRender },
        siteBounds: tempCopy,
        totalArea: totalArea / 4047,
        keepoutsArea: keepoutsArea / 4047,
        //to convert square meters to acres
        //divide value by 4047
      });

      dispatch({
        type: "SET_PATH",
        file: file,
        utmZoneNumber: res.data.result.UTMZoneNumber,
        utmZoneLetter: res.data.result.UTMZoneLetter,
        projectLand: res.data.result.projectLand,
        projectLandUTM: res.data.result.projectLandUTM,
      });
    })
    .catch((err) => console.log(err));
};

export const TwoPeeHandler = (state, layoutData) => (dispatch) => {
  if (state) {
    dispatch({
      type: "SET_2P",
      payload: state,
      gap: layoutData.module_gap_ew,
    });
  } else {
    dispatch({
      type: "SET_2P",
      payload: state,
      gap: 0,
    });
  }
};

export const updateProjectState = (newState, prevState) => (dispatch) => {
  for (let property in prevState) {
    for (let prop in newState) {
      if (property === prop) {
        prevState[property] = newState[prop];
      }
    }
  }
  dispatch({
    type: "UPDATE_PROJECT_STATE",
    payload: prevState,
  });
};

export const loadTables = (tables, piles) => (dispatch) => {
  dispatch({
    type: "LOAD_TABLE_LOCATIONS",
    payload: tables,
    pileLocations: piles,
  });
};
export const loadPiles = (data) => (dispatch) => {
  dispatch({
    type: "LOAD_PILE_LOCATIONS",
    payload: data,
  });
};

export const uploadGeneratedFiles = (user, state, files) => {
  files.map((file) => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("project_name", state.project_name);
    formData.append("username", user.username);
    formData.append("owner_id", user.id);
    formData.append("boxFolderId", user.boxFolderId);
    axios
      .post(`${process.env.REACT_APP_BACKEND}/uploads/upload`, formData)
      .catch((err) => console.log(err));
  });
};

export const getFiles = (project, state, user) => (dispatch) => {

  let landData
  let sender = {
    project: project,
    user: user,
  };
  axios
    .post(`${process.env.REACT_APP_BACKEND}/projects/getFiles/`, sender)
    .then(async (res) => {
      landData = await res.data.landData;
      if (landData) {
        // console.log(res.data.landData.project_data.project_data.InverterInstances)
        let project_data_response = await res.data.landData.project_data.project_data;
        let inverterInstanceResponse = project_data_response.InverterInstances;
        dispatch(getInverterById(inverterInstanceResponse.inverter_id));
        dispatch(
          updateProjectState(inverterInstanceResponse, state.InverterInstances),
        );

        let satRowResponse = project_data_response.SATRowCfg;
        dispatch(getModuleById(satRowResponse.module_id));
        dispatch(updateProjectState(satRowResponse, state.SATRowCfg));
        dispatch(
          updateProjectState(
            project_data_response.ProjectData,
            state.ProjectData,
          ),
        );
        dispatch(
          updateProjectState(project_data_response.LayoutCfg, state.LayoutCfg),
        );
        let keepoutsToRender = [];
        let totalArea = 0;
        const projectLand = await landData.projectLand.map((item) => item);
        const keepouts = await res.data.landData.keepouts.map((item) => item);

        keepouts?.map((keepout) => {
          let newCoords = [];
          keepout.geometry.coordinates.map((item) => {
            item.map((temp) => {
              let latLng = utm.convertUtmToLatLng(
                temp[1],
                temp[0],
                keepout.properties.origin.ZoneNumber,
                keepout.properties.origin.ZoneLetter,
              );
              return newCoords.push([latLng.lng, latLng.lat]);
            });
          });
          let copy = { ...keepout };
          copy.geometry.coordinates = [newCoords];
          keepoutsToRender.push(copy);
        });

        let sitePolygon = res.data.landData.siteBoundingBox.sitePoly;
        let polyOrigin =
          res.data.landData.siteBoundingBox.sitePoly.properties.origin;
        let center =
          res.data.landData.siteBoundingBox.sitePoly.properties.center.geometry
            .coordinates;
        let centerPoint = utm.convertUtmToLatLng(
          center[1],
          center[0],
          polyOrigin.ZoneNumber,
          polyOrigin.ZoneLetter,
        );

        let newBboxCoords = [];
        sitePolygon.geometry.coordinates.map((item) => {
          item.map((temp) => {
            let latLng = utm.convertUtmToLatLng(
              temp[1],
              temp[0],
              polyOrigin.ZoneNumber,
              polyOrigin.ZoneLetter,
            );
            newBboxCoords.push([latLng.lng, latLng.lat]);
          });
        });
        let tempCopy = { ...sitePolygon };
        tempCopy.geometry.coordinates = [newBboxCoords];
        tempCopy.properties.center = centerPoint;

        //eslint-disable-next-line
        let projectLandToRender = [];

        //convert UTM to latlng to render on map

        for (let land of projectLand) {
          let newCoords = [];
          land.geometry.coordinates.map((item) => {
            item.map((temp) => {
              let latLng = utm.convertUtmToLatLng(
                temp[1],
                temp[0],
                land.properties.origin.ZoneNumber,
                land.properties.origin.ZoneLetter,
              );
              newCoords.push([latLng.lng, latLng.lat]);
            });
          });

          let copy = { ...land };

          copy.geometry.coordinates = [newCoords];
          copy.properties.area = areaOf(copy);
          land.properties.area = areaOf(copy);
          totalArea += land.properties.area;
          projectLandToRender.push(copy);
        }
        let projectLandAreas = {};
        for (let i = 0; i < res.data.landData.projectLand.length; i++) {
          projectLandAreas[i] = res.data.landData.projectLand[i];
        }
        let response = res.data.landData.project_data.project_data;
        response.ProjectData.project_id = project;

        let loadedPiles = await getPilePoints(
          res.data.motorPiles,
          response.SATRowCfg.sat_row_width,
          response.SATRowCfg.sat_row_length,
          response.SATRowCfg.n_piles,
          tempCopy.properties.origin,
        );
        dispatch({
          type: "GET_LAND_AREAS",
          landFile: res.data.landData.project_data.landFile,
          payload: projectLandAreas,
          keepouts: { keepouts: keepoutsToRender },
          siteBounds: tempCopy,
          totalArea: totalArea / 4047,
        });
        

        dispatch({
          type: "SET_PATH",
          utmZoneNumber: res.data.landData.siteBoundingBox.sitePoly.properties.origin.ZoneNumber,
          utmZoneLetter: res.data.landData.siteBoundingBox.sitePoly.properties.origin.ZoneLetter,
          projectLand: res.data.landData.projectLand,
          projectLandUTM: res.data.landData.projectLandUTM,
        });

        dispatch(
          loadTables(
            { tables: res.data.tableLocations },
            { piles: loadedPiles },
          ),
        );

        dispatch(setLoadingTrue());
        dispatch(setViewingTrue());
      }else {
        landData = await axios.post(`${process.env.REACT_APP_BACKEND}/projects/getFiles/`, sender)
        console.log(landData)

        errorMessage("A critical error has occured while loading your project.");


      }
    })
    .catch((err) => {
      errorMessage("A critical error has occured while loading your project.");
      console.trace(err);
    });
};

export const downloadProjectFiles = (projectId, user) => (dispatch) => {
  let sender = {
    project: projectId,
  };
  axios
    .post(`${process.env.REACT_APP_BACKEND}/projects/downloadFiles`, sender)
    .then((res) => {
      window.open(res.data.link);
      getResultsPdf(res.data.project);
      // Default export is a4 paper, portrait, using millimeters for units
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadTableDataCsv =
  (username, state, file) => async (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("project_name", state.project_name);
    formData.append("username", username);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND}/uploads/upload/tableCsv`,
        formData,
      )
      .then((res) => {
        let csvTablesToRender = [];

        for (let table of res.data.tables) {
          //eslint-disable-next-line

          let newTable = [];
          table.geometry.coordinates[0].map(async (coords) => {
            let latLng = utm.convertUtmToLatLng(
              coords[0],
              coords[1],
              table.properties.origin.ZoneNumber,
              table.properties.origin.ZoneLetter,
            );

            newTable.push([latLng.lng, latLng.lat]);
          });
          csvTablesToRender.push({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [newTable],
            },
            properties: {
              insideBuildableArea: "true",
            },
          });
        }

        dispatch({
          type: "SIMPLE_LAYOUT",
          payload: csvTablesToRender,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
