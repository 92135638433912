import React from 'react';
import {Route} from 'react-router-dom';
import { connect } from 'react-redux';

//components
// import PrivateRoute from './routeUtils';
import Splash from './splash/Splash';
import UserDashboard from './user/UserDashboard';
import CreateProject from './createProject/CreateProject';
import PasswordRecovery from './authentication/PasswordRecovery';
import PasswordReset from './authentication/PasswordReset';
const Routes = (props) => {
    
    return (
        <div>
            <Route path='/' exact  component={Splash}/>
            <Route path='/:user/dashboard' component={UserDashboard} />
            <Route path='/:user/createproject' component={CreateProject} />
            <Route path='/forgotpassword' component={PasswordRecovery} />
            <Route path='/reset-password' component={PasswordReset} />
            
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
   
}

export default connect(mapStateToProps)(Routes);
