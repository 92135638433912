import React from "react";
import { connect } from "react-redux";
//components
import ProjectListItem from "./ProjectListItem";

const ProjectsComponent = (props) => {
  let projects = Object.values(props.user.projects);
 
  let filteredProjects = projects.filter(item => item.project_data !== null)
  return (
    <div className="projects-container">
      {filteredProjects.map((project) => {
        return <ProjectListItem key={project.id} project={project} />;
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ProjectsComponent);
