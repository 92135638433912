import React, { useState } from "react";
import { connect } from "react-redux";
import scss from "./arrayAreaBreakdown.module.scss";

const ArrayAreaBreakdown = (props) => {
  const [landArray, setLandArray] = useState(Object.values(props.arrayAreas));
  const [isFiltered, setFiltered] = useState(false);
  const [areasFiltered, setAreasFiltered] = useState(0);
  const filter = (landArray) => {
    let filteredLand = landArray.filter(
      (land) => land.properties.tablesInArea.length,
    );
    setLandArray(filteredLand);
    let empties = landArray.length - filteredLand.length;
    setAreasFiltered(empties);
    setFiltered(true);
  };
  const showAllAreas = () => {
    setLandArray(props.arrayAreas);
    setFiltered(false);
  };
  return (
    <div className={scss["area-breakdown-container"]}>
      <h4 className={scss["title"]}>Array Area Breakdown</h4>
      {isFiltered ? (
        <p className={scss["areas-filtered"]}>
          Filtering {areasFiltered} empty areas
        </p>
      ) : null}
      <div className={scss["array-area-breakdown"]}>
        {landArray.map((arrayArea) => {
          return (
            <div key={arrayArea.properties.areaId} className={scss["array-area"]}>
              <p className={scss["title"]}>
                Area {arrayArea.properties.areaId}
              </p>
              <p className={scss["stat"]}>
                {props.cfg.sat_table_name} x{" "}
                {arrayArea.properties.tablesInArea.length}
              </p>
              <p className={scss["stat"]}>
                {" "}
                power generated:{" "}
                {(
                  (props.cfg.sat_row_dc_power *
                    arrayArea.properties.tablesInArea.length) /
                  1000000
                ).toLocaleString()}{" "}
                MW
              </p>
            </div>
          );
        })}
      </div>
      {/* ARRAY AREA BREAKDOWN */}
      {isFiltered ? (
        <button className={scss["filter-button"]} onClick={showAllAreas}>
          Show all areas
        </button>
      ) : (
        <button
          className={scss["filter-button"]}
          onClick={() => filter(landArray)}
        >
          Filter empty areas
        </button>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    cfg: state.armadillo.SATRowCfg,
    arrayAreas: state.armadillo.updatedAreas,
  };
};

export default connect(mapStateToProps)(ArrayAreaBreakdown);
