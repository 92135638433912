import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { validateUser } from "Redux/actions/authenticationActions";
import { getModuleMakers } from "Redux/actions/electrical/electricalData";
import { updateProjectState } from "Redux/actions/ArmadilloActions";
import { errorMessage } from "Redux/actions/notificationActions";
//components
import CreationToolbox from "./tools/CreationToolbox";
import GeneralDataModal from "../../modals/GeneralData/GeneralDataModal";
import LandDataModal from "../../modals/LandData/LandDataModal";
import ElectricDataModal from "../../modals/ElectricData/ElectricDataModal";
import TrackerLayoutModal from "../../modals/TrackerCfg/TrackerLayoutModal";
import PvLayoutModal from "../../modals/PvLayout/PvLayoutModal";
import StructuralModal from "components/modals/StructuralModal/StructuralModal";
import ProjectConfirmationModal from "../../modals/Confirmation/ProjectConfirmationModal";
import ProjectResultsModal from "components/modals/ProjectResults/ProjectResultsModal";

import ReactMap from "../../map/ReactMap";
const CreateProject = (props) => {
  const dispatch = useDispatch();

  const [confirmProject, setConfirmProject] = useState(false);
  const modalKeys = [
    {
      id: 1,
      text: "General",
    },
    {
      id: 2,
      text: "Land",
    },
    {
      id: 3,
      text: "Electrical",
    },
    {
      id: 4,
      text: "Table",
    },
    {
      id: 5,
      text: "Layout",
    },
    {
      id: 6,
      text: "Structural",
    },
  ];

  const toggleConfirm = (inverter, satRow) => {
    if (props.inverter === undefined) {
      errorMessage("You must select an inverter");
    } else if (!props.armadillo.SATRowCfg.sat_table_name) {
      errorMessage("You must configure a tracker row");
    } else {
      setConfirmProject(!confirmProject);
    }
  };

  const [modals, setModals] = useState({
    general: false,
    land: false,
    electrical: false,
    table: false,
    layout: false,
    structural: false,
    results: false,
  });

  const saveAndClose = (data) => {
    dispatch(updateProjectState(data, props.project));
  };
  const toggleModals = (e, data) => {
    let split = e.target.className.split(" ");
    setModals({ ...modals, [split[0]]: !modals[split[0]] });
    if (data) {
      saveAndClose(data);
    }
  };

  const toggleResults = () => {
    setModals({ ...modals, results: !modals.results });
  };

  const closeAll = () => {
    setModals({
      general: false,
      land: false,
      electrical: false,
      table: false,
      layout: false,
      structural: false,
      
    });
  };

  let projectLand = Object.values(props.armadillo.projectLandAreas);
  useEffect(() => {
    dispatch(validateUser());
    dispatch(getModuleMakers());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.user.id) {
      let copy = { ...props.armadillo.ProjectData };
      copy.owner_id = props.user.id;
      dispatch(updateProjectState(copy, props.armadillo.ProjectData));
    }

    //eslint-disable-next-line
  }, [props.user]);


  return (
    <div className="create-project">
      {modals.general ? <GeneralDataModal toggleModals={toggleModals} /> : null}
      {modals.land ? <LandDataModal toggleModals={toggleModals} /> : null}
      {modals.electrical ? (
        <ElectricDataModal toggleModals={toggleModals} />
      ) : null}
      {modals.table ? <TrackerLayoutModal toggleModals={toggleModals} /> : null}
      {modals.layout ? <PvLayoutModal toggleModals={toggleModals} /> : null}
      {modals.structural ? (
        <StructuralModal toggleModals={toggleModals} />
      ) : null}
      {confirmProject ? (
        <ProjectConfirmationModal toggleConfirm={toggleConfirm} />
      ) : null}
      {modals.results ? (
        <ProjectResultsModal toggleResults={toggleResults} />
      ) : null}

      <div className="main">
        <CreationToolbox
          modalKeys={modalKeys}
          toggleModals={toggleModals}
          toggleConfirm={toggleConfirm}
          closeAll={closeAll}
        />
        {projectLand.length > 0 ? <ReactMap /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tables: state.tables.selectedTables,
    armadillo: state.armadillo,
    user: state.user.userData,
    inverter: state.electrical.selectedInverter,
  };
};

export default connect(mapStateToProps)(CreateProject);
