import React from "react";
import { useDispatch, connect } from "react-redux";
import { uploadKMZFile } from "Redux/actions/userActions";
//styles
import scss from "./landDataModal.module.scss";

const LandDataModal = (props) => {
  let file = "";

  const dispatch = useDispatch();
  let user = props.user.userData;
  //
  // const handleDragEnter = (e) => {
  //   e.preventDefault();
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  //   e.dataTransfer.dropEffect = "move";
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   let files = [...e.dataTransfer.files];
  //   console.log(files);
  //   let file = files[0]
  //   dispatch(uploadKMZFile(userName, props.project,file));

  // };

  const onFileInputChange = (event) => {
    event.preventDefault();

    const { files } = event.target;

    file = files["0"];

    dispatch(uploadKMZFile(user, props.armadillo, file));
  };
  return (
    <div className={scss["land-data-modal"]}>
      <h4>Upload your Project Land file here</h4>
      {props.project.project_address === "" ? null : (
        <div className={scss["address"]}>
          <h4 className={scss["address-output"]}>
            {props.project.project_address}
          </h4>
        </div>
      )}

      <input
        onChange={onFileInputChange}
        type="file"
        className={scss["file-select"]}
        disabled={!props.armadillo.project_name}
      />

      {/* <h4>Upload any additional constraints here</h4>
      <input
        onChange={onFileInputChange}
        type="file"
        className={scss["file-select"]}
        disabled={!props.armadillo.project_name}
      /> */}

      {/* <div
      className="drop-zone"
      onDrop={handleDrop}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
    >
      <p>Drag your file here.</p>
      <ol>
        {props.project.fileList?.map((file) => {
          return (
            <li key={file.name}>
              <div className="FileComponent">
                <p>{file.name}</p>
                <img
                  src={file.preview}
                  alt=""
                  style={{ width: 50, height: 50 }}
                />
                <button className="erase-file">Erase</button>
              </div>
            </li>
          );
        })}
      </ol>
    </div> */}

      <div className="buttons">
        <button className="land" onClick={(e) => props.toggleModals(e)}>
          Close
        </button>
        <button
          className="land save-and-close"
          onClick={(e) => props.toggleModals(e)}
        >
          Save and Close
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    project: state.project,
    user: state.user,
    armadillo: state.armadillo.ProjectData,
  };
};

export default connect(mapStateToProps)(LandDataModal);
