import React from 'react';
import scss from './projectResults.module.scss';
const Projectresultsmodal = () => {
    return (
        //regurgitate project attributes
        //total acres available
        //total acres usable: total area  - keepouts/constraints
        //total # tracker rows by config
        //minimum number of inverters

        //array area breakdown
        //array area id
        //acres
        //subtotal MWDC (megawatts dc)


        <div className={scss["project-results"]}>
            <h3 className={scss["results-title"]}>Project Name results</h3>
            <div className={scss["project-data"]}>
                Project data here
            </div>
            <h3 className={scss["total-acres"]}>123456889</h3>
            <h3 className={scss["usuable-acres"]}>1234567.5</h3>
            <h3 className={scss["total-rows"]}>10000</h3>
            <h3 className={scss["min-inverters"]}>10000</h3>

            <div className={scss["array-area-breakdown"]}>
                Array Area Brakdown
            </div>
        </div>
    );
}

export default Projectresultsmodal;
