import React from "react";
import { connect, useDispatch } from "react-redux";
import { addToSelectedModules, setSelectedModule } from "Redux/actions/electrical/electricalData";
import { getTrackerDimensions } from "Redux/actions/armadillo/getTrackerDimensions";
import { toast } from "react-toastify";
import scss from "./moduleSearch.module.scss";

//components
import Module from "./Module";
const ModuleSelect = (props) => {
  const moduleAddedMessage = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const dispatch = useDispatch();
  const tableClickHandler = (module) => {
    let split = module.manufacturer.split(" ");
    let truncatedManufacturer = split[0];
    let truncatedModule = module.name.replace(
      module.manufacturer,
      truncatedManufacturer,
    );
    let moduleCopy = {...module}
    moduleCopy.name= truncatedModule
    dispatch(addToSelectedModules(moduleCopy));
    dispatch(setSelectedModule(moduleCopy));
    dispatch(getTrackerDimensions(moduleCopy, props.cfg));
   
  

    moduleAddedMessage(`${moduleCopy.name}has been added to project.`);

    props.resetString();
  };

  return (
    <div className={scss["module-select"]}>
      {props.displayedModules.map((table, i) => {
        return <Module key={i} data={table} function={tableClickHandler} />;
      })}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    selected: state.electrical.selectedModule,
    project: state.project,
    cfg: state.armadillo.SATRowCfg,
  };
};
export default connect(mapStateToProps)(ModuleSelect);
