import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { updateProjectState } from "Redux/actions/ArmadilloActions";
import  { setDcAcRatio } from "Redux/actions/electrical/electricalData";
import  { errorMessage } from "Redux/actions/notificationActions";

const GeneralDataModal = (props) => {
  const dispatch = useDispatch();
  const [projectGeneralData, setGeneralData] = useState({
    project_name: props.armadillo.project_name,
    owner_id: props.armadillo.owner_id,
    target_dcac_ratio: props.ratio,
  });

  const changeHandler = (e) => {
    setGeneralData({
      ...projectGeneralData,
      [e.target.name]: e.target.valueAsNumber || e.target.value,
    });
  };
  const handleBlur = () => {
    dispatch(updateProjectState(projectGeneralData, props.armadillo));
  };
  const ratioHandler = () => {
    if(projectGeneralData.target_dcac_ratio >= 1 && projectGeneralData.target_dcac_ratio <=2) {
      dispatch(setDcAcRatio(projectGeneralData.target_dcac_ratio))
    }else {
      setGeneralData({...projectGeneralData,target_dcac_ratio: 1})
      errorMessage("DC/AC ratio must be between 1 and 2")
    }
    
  }

  const closeModal = (e) => {
    if(projectGeneralData.target_dcac_ratio === 0) {
      errorMessage("A DC/AC ratio is required")
    }else if (!projectGeneralData.project_name) {
      errorMessage("A project name is required")
    }else {
      props.toggleModals(e, projectGeneralData)
    }
  }

  return (
    <div className="project-general-data-modal modal">
      <h3 className="project-name-header">Project Name</h3>
      <input
        type="text"
        placeholder="Enter Project Name"
        className="project-name"
        onChange={changeHandler}
        onBlur={handleBlur}
        name="project_name"
        value={projectGeneralData.project_name}
      />

      <h3 className="project-address">{props.project.project_address}</h3>

      <form>
        <div className="input-container">
          <label htmlFor=" target_dcac_ratio">Target DC/AC ratio</label>
          <input
            type="number"
            placeholder="dc/ac ratio"
            name="target_dcac_ratio"
            value={projectGeneralData.target_dcac_ratio}
            onChange={changeHandler}
            onBlur={ratioHandler}

          />
        </div>
      </form>

      <div className="buttons">
      <button
          className="general close"
          onClick={(e) => props.toggleModals(e)}
        >
          Close
        </button>
    
        <button
          className="general save-and-close"
          onClick={(e) => closeModal(e)}
        >
          Save and Close
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    project: state.project,
    user: state.user,
    armadillo: state.armadillo.ProjectData,
    ratio: state.electrical.targetDcAcRatio
  };
};

export default connect(mapStateToProps)(GeneralDataModal);
