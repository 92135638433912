import { clearLandAreas, updateProjectState } from "../ArmadilloActions";

export const resetState = (armadillo) => (dispatch) => {
    // let projectData = {
    //     project_id: 0,
    //     owner_id: 0,
    //     project_server_path: "",
    //     project_client_path: "",
    //     project_name: "",
    //     module_name: "",
    //     inverter_name: "",
    //     sat_row_cfg_name: "",
    //     layout_cfg: "",
    //   }
    //   let layout =  {
    //     azimuth: 180,
    //     skew: 0,
    //     gap_NS_distance: 5,
    //     gap_EW_distance: 5,
    //     extra_gap_NS_freq: 0,
    //     extra_gap_NS_dist: 0,
    //     extra_gap_EW_freq: 0,
    //     extra_gap_EW_dist: 0,
    //   }
    //   let satRow = {
    //     sat_table_name: "",
    //     sat_model_name: "",
    //     strings_per_table: 3,
    //     string_length: 28,
    //     module_orientation: "",
    //     pile_coords: [],
    //     drive_coords: [],
    //     string_drop_coords: [],
    //     module_coords: [],
    //     sat_row_length: 100,
    //     sat_row_width: 6,
    //     sat_row_dc_power: 0,
    //     n_piles: 11,
    //     module_id: 0,
    //   }

   
    //   let structural = {
    //     structure_bodid: 0,
    //     pile_type: "",
    //     modules_high: 0,
    //     ns_slope_tol_1: 0,
    //     ns_slope_tol_2: 0,
    //     ew_slope_tol_1: 0,
    //     ew_slope_tol_2: 0,
    //     min_reveal: 0,
    //     max_reveal: 0,
    //     max_scour: 0,
    //     max_flood: 0,
    //     max_exposure: 0,
    //   }

    //   let misc = {
    //     landFilePath: "",
    //     project_id: 0,
    //     sat_row_id: 0,
    //     tableLocations: { tables: [] },
    //     pileLocations: { piles: [] },
    //     motorPiles: [],
    //     projectLandAreas: {},
    //     updatedAreas: {},
    //     keepouts: {},
    //     siteBounds: [],
    //     statusMessage: "",
    //     totalArea: 0,
    //     keepoutsArea: 0
    //   }
    let fresh = {
      ProjectData: {
        project_id: 0,
        owner_id: 0,
        project_server_path: "",
        project_client_path: "",
        project_name: "",
        module_name: "",
        inverter_name: "",
        sat_row_cfg_name: "",
        layout_cfg: "",
      },
      LayoutCfg: {
        azimuth: 180,
        skew: 0,
        gap_NS_distance: 5,
        gap_EW_distance: 5,
        gcr: 0.33,
        extra_gap_NS_freq: 0,
        extra_gap_NS_dist: 0,
        extra_gap_EW_freq: 0,
        extra_gap_EW_dist: 0,
      },
      SATRowCfg: {
        sat_table_name: "",
        sat_model_name: "",
        strings_per_table: 3,
        string_length: 28,
        module_orientation: "",
        pile_coords: [],
        drive_coords: [],
        string_drop_coords: [],
        module_coords: [],
        sat_row_length: 100,
        sat_row_width: 6,
        sat_row_dc_power: 0,
        n_piles: 11,
        module_id: 0,
        is2p: false,
        module_gap_ns: 0.025,
        module_gap_ew: 0.025,
        bearing_gap: 0.15,
        torque_tube_overhang: 0.2,
        drive_gap: 1.1,
      },
      SATRowInstances: {
        project_id: 0,
        sat_row_id: 0,
        // sat_cfg_id: 0, to be handled in instance identity table, seperate from geometry
        n_coords: 0,
        e_coords: 0,
        z_coords: 0,
        tt_slope: 0,
        // dc_block_id: 0, to be handled in instance identity table, seperate from geometry
        // inverter_id: 0, to be handled in instance identity table, seperate from geometry
        // structure_bodid: 0, to be handled in instance identity table, seperate from geometry
      },
      InverterInstances: {
        inverter_id: 0,
        nez_coords: [],
        mv_circuit_num: 0,
        mv_circuit_order: 0,
      },
      SATRowStructural: {
        structure_bodid: 0,
        pile_type: "",
        modules_high: 0,
        ns_slope_tol_1: 0,
        ns_slope_tol_2: 0,
        ew_slope_tol_1: 0,
        ew_slope_tol_2: 0,
        min_reveal: 0,
        max_reveal: 0,
        max_scour: 0,
        max_flood: 0,
        max_exposure: 0,
      },
      landFile: "",
      project_id: 0,
      sat_row_id: 0,
      tableLocations: { tables: [] },
      pileLocations: { piles: [] },
      motorPiles: [],
      projectLandAreas: {},
      updatedAreas: {},
      keepouts: {},
      siteBounds: [],
      statusMessage: "",
      totalArea: 0,
      keepoutsArea: 0,
    };

      
    
      dispatch( updateProjectState(fresh.ProjectData,armadillo.ProjectData))
      dispatch( updateProjectState(fresh.SATRowCfg,armadillo.SATRowCfg))
      dispatch( updateProjectState(fresh.LayoutCfg,armadillo.LayoutCfg))
      dispatch( updateProjectState(fresh.InverterInstances,armadillo.InverterInstances))
    //  dispatch(updateProjectState(fresh, armadillo))
     dispatch(clearLandAreas())
     

    }