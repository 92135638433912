const initialState = {
  submissionState: undefined,
  layout_type: "simple mechanical",
  utmZoneLetter: "",
  utmZoneNumber: 0,
  utm_bbox: [],
  origin: [],
  keepouts: [],
  keepoutsToRender: [],
  projectLand: [],
  projectLandUTM: [],
  siteBounds: [],
  projectLandFeatureCollection: undefined,
  project_name: "",
  project_address: "",
  kmz_file: undefined,
  table_name: "",
  fileList: [],
  path: [],
  utmCoords: [],
  utmPair: [],
  r_path: [],
  region_selection: [],
  //tracker
  is2p: false,
  height: 0,
  rows: 0,
  columns: 0,
  strings: 0,
  module_gap: 2,
  module_gap_ew: 0,
  string_size: 28,
  driveline_gap: 28,
  driveline_frequency: 36,
  bearing_gap: [[6], 8],
  column_gap: "1,1,1,1",
  cols_per_table: 4,
  //pvlayout
  azimuth: 180,
  skew_angle: 0,
  orientation: "portrait",
  min_array_height: 0,
  max_array_height: 0,
  max_slope: 0,
  ns_gap: 8,
  ew_gap: 32,
  max_ns_gap: 0,

  additional_ns_gap_frequency: 0,
  additional_ns_gap_dimensions: 0,
  additional_ew_gap_frequency: 0,
  additional_ew_gap_dimensions: 0,
  //electrical
  target_dcac_ratio: 0,
  target_annual_energy: 0,

  selectedInverters: [],
  inverter_name: "",
  selectedModules: [],
  selectedModuleId: 0,
  pvLayout: {},
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROJECT_NAME":
      return {
        ...state,
        project_name: action.payload,
      };
      case "SET_SUBMISSION_STATE":
      return {
        ...state,
        submissionState: action.payload,
      };
    case "SET_PROJECT_REGION":
      return {
        ...state,
        region_selection: action.payload,
      };
    case "SET_LAYOUT_TYPE":
      return {
        ...state,
        layout_type: action.payload,
      };

    case "SET_PROJECT_ADDRESS":
      return {
        ...state,
        project_address: action.payload,
      };
    case "ADD_TO_SELECTED_MODULES":
      return {
        ...state,
        selectedModules: [...state.selectedModules, action.payload],
      };
    case "ADD_TO_SELECTED_INVERTERS":
      return {
        ...state,
        selectedInverters: [...state.selectedInverters, action.payload],
        inverter_name: action.inverter_name,
      };
    case "SET_2P":
      return {
        ...state,
        is2p: action.payload,
        module_gap_ew: action.gap,
      };
    // case "UPDATE_PROJECT_STATE":
    //   return {
    //     ...state,
    //     state: action.payload,
    //   };
    case "GET_GENERATED_FILES":
      return {
        ...state,
        fileList: [...state.fileList, action.payload],
      };

    case "SET_BOUNDING_BOX":
      return {
        ...state,
        utm_bbox: action.payload,
      };

    case "SET_PATH":
      return {
        ...state,
        path: action.path,
        utmCoords: action.utmCoords,
        utmPair: action.utmPair,
        kmz_file: action.file,
        fileList: [...state.fileList, action.file],
        utmZoneLetter: action.utmZoneLetter,
        utmZoneNumber: action.utmZoneNumber,
        utm_bbox: action.bbox,
        origin: action.origin,
        keepouts: action.keepouts,
        keepoutsToRender: action.keepoutsToRender,
        projectLand: action.projectLand,
        projectLandUTM: action.projectLandUTM,
        siteBounds: [...state.siteBounds, action.siteBounds]
      };

      case "SET_PROJECT_LAND_FEATURE_COLLECTION" :
        return {
          ...state,projectLandFeatureCollection: action.payload
        }
    case "SET_TABLE_NAME":
      return {
        ...state,
        table_name: action.payload,
      };

    default:
      return state;
  }
};

export default projectReducer;
