import React, { useState,useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import scss from "./editCfgs.module.scss";
import {getModuleMakers} from "Redux/actions/electrical/electricalData"
import { getTrackerDimensions } from "Redux/actions/armadillo/getTrackerDimensions";

//components
import SavedCfg from "./SavedCfg";

import CreateNew from "../TrackerCfg/CreateNew";
const EditCfgs = (props) => {
  const dispatch = useDispatch();
  const closeHandler = () => {
    props.closeModal(false);
  };
  const [isCreatingNew, setCreatingNew] = useState(false);
  const creationHandler = () => {
 
    setCreatingNew(!isCreatingNew);
  };
  const [satRowCfg, setCfg] = useState({
    ...props.armadillo,
  });
  const simpleChangeHandler = (e) => {
    setCfg({
      ...satRowCfg,
      [e.target.name]: e.target.valueAsNumber || e.target.value,
    });
  };

  const setModuleId = (data) => {
    let copy = {...satRowCfg}
    copy.module_id = data
    setCfg(copy)
  }

  useEffect(() => {
    dispatch(getModuleMakers())
   
    //eslint-disable-next-line
  }, [])
  useEffect(() => {
    getTrackerDimensions(props.selectedModule,satRowCfg)
    setCfg(props.armadillo);
    //eslint-disable-next-line
  },[props.selectedModule])
  return (
    <>
      {isCreatingNew ? (
       <div className={scss["creation-container"]}>
          <CreateNew
          setCfg={setCfg}
          satRowCfg={satRowCfg}
          simpleChangeHandler={simpleChangeHandler}
          setModuleId={setModuleId}
          armadillo={props.armadillo}
           />
          <button onClick={creationHandler} className="cancel-creation">Cancel</button>
       </div>
      ) : (
        <div className={scss["edit-tracker-cfgs"]}>
          {props.saved.length ? props.saved.map((savedCfg) => {
            let cfgData = savedCfg.tableCfg;

            return (
              <SavedCfg
                key={savedCfg.id}
                savedCfg={savedCfg}
                cfgData={cfgData}
              />
            );
          }) : <h3>Configure a tracker row here.</h3> }

          <button onClick={creationHandler} className={scss["create-new"]}>
            Create New
          </button>
          <button onClick={closeHandler} className={scss["close"]}>
            Close
          </button>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    saved: state.user.savedSatRowCfgs,
    project: state.project,
    armadillo: state.armadillo.SATRowCfg,
    selectedModule: state.electrical.selectedModule
    
  };
};
export default connect(mapStateToProps)(EditCfgs);
