import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setSATRowCfg, setSelectedTable } from "Redux/actions/ArmadilloActions";
import { getModuleById } from "Redux/actions/electrical/electricalData";


import scss from "./savedSatRows.module.scss";

const SavedSatRows = (props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(props.cfg);
  const clickHandler = (e) => {
    console.log(props.savedSatRows[e.target.value])
    if (props.savedSatRows[e.target.value] !== undefined) {
      dispatch(setSelectedTable(props.savedSatRows[e.target.value]));
      
      setSelected(props.savedSatRows[e.target.value].tableCfg);
      dispatch(setSATRowCfg(props.savedSatRows[e.target.value].tableCfg))
      dispatch(getModuleById(props.savedSatRows[e.target.value].tableCfg.module_id))
    }
     
    
  };
  return (
    <div className={scss["saved-sat-rows"]}>
      <h4 className={scss["recent-configs"]}>Recent Configurations</h4>
      <select
        onClick={(e) => clickHandler(e)}
        className={scss["recent-configs-select"]}
      >
        <option hidden>
          {selected.sat_table_name ? selected.sat_table_name : "Pick one"}
        </option>
        {props.savedSatRows?.map((satRow) => {
          let index = props.savedSatRows.indexOf(satRow);
          return (
            <option key={satRow.id} value={parseInt(index)}>
              {satRow.tableCfg.sat_table_name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    savedSatRows: state.user.savedSatRowCfgs,
    cfg: state.armadillo.SATRowCfg,
    user: state.user.userData,
    selectedModule: state.electrical.selectedModule,
    selectedTable: state.tables.selectedTable,
  };
};

export default connect(mapStateToProps)(SavedSatRows);
