export const inverter = {
  ceceff: "",
  cecrating: "",
  clonedfrom: "",
  dcac: "1.3",
  dcpwrinv: "",
  dimx: "106.29927",
  dimy: "23.62206",
  dimz: "78.7402",
  eff: "",
  enclosure: "",
  eureff: "",
  extstrmon: "",
  fq: "8.0",
  fr: "",
  freqrange: "",
  gnddcc: "",
  harmonicdist: "",
  hsid: "",
  iac: "1144.0",
  invsamitemid: "",
  invtype: "Central (Monopolar)",
  last_updated: "20190314.0",
  manuf: "AEG Power Solutions",
  maxbfiac: "",
  maxmppv: "820.0",
  maxscidc: "",
  midc: "1060.0",
  minv: "500.0",
  modelname: " Protect PV.500-UL 480V [CEC 2013]",
  mpptqty: "",
  mvdc: "1000.0",
  name: "AEG Power Solutions Protect PV.500-UL 480V [CEC 2013]",
  nommppv: "",
  notes: "From NREL SAM Library; 1.1-Updated NrelModName",
  nphase: "3.0",
  nrelinvname: "AEG Power Solutions: Protect PV.500-UL",
  obsolete: "",
  ocpd: "",
  productline: "Protect PV",
  pvbidmapping: "1.0",
  pvsystinvname: "",
  pwrnight: "123.0",
  rating: "510.0",
  rcbreq: "",
  region: "All",
  seedstemp: "",
  specsheet: "PV-500-630-UL",
  specsheetver: "",
  topology: "",
  unitcost: "",
  vac: "480.0",
  vachigh: "",
  vaclow: "",
  vdcpwr: "",
  version: "1.1",
  viewgroup: "",
  wirecfg: "",
};
