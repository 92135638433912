import { setSATRowCfg } from "../ArmadilloActions";

export const getTrackerDimensions = (module, cfg) => (dispatch) => {
  let cfgCopy = { ...cfg };
  if (module) {
    let moduleLength;
    if (cfg.is2p === "true") {
      moduleLength = module.length * 2 + cfg.module_gap_ew;
    } else {
      moduleLength = module.length;
    }
    // let moduleLength = module.length;
    let moduleWidth = module.width;
    let TTO = cfg.torque_tube_overhang;
    let BG = cfg.bearing_gap;
    let MG = cfg.module_gap_ns;
    let N_PILES = cfg.n_piles;
    let DG = cfg.drive_gap;
    let N_MODS = cfg.string_length * cfg.strings_per_table;
    //tracker row length formula for 1p
    // 2 * TTO + N_MODS * width + (N_MODS - 1) * MG + N_PILES * BG + DG
    let trackerRowLength;
      
    let totalPowerPerRow =
      cfg.is2p === "true" ? N_MODS * module.STC * 2 : N_MODS * module.STC;
    if(cfg.is2p === "true") {
      trackerRowLength = 2* TTO + (N_MODS/2)*moduleWidth+(N_MODS/2-1)*MG+ N_PILES *BG + DG
    }else {
      trackerRowLength = 2 * TTO + N_MODS * moduleWidth + (N_MODS - 1) * MG + N_PILES * BG + DG;
    }
    cfgCopy.sat_row_length = trackerRowLength;
    cfgCopy.sat_row_width = moduleLength;
    cfgCopy.sat_row_dc_power = totalPowerPerRow;
    cfgCopy.module_id = module.id;
    
    dispatch(setSATRowCfg(cfgCopy));
  } else {
    console.log("no module found bro");
    dispatch(setSATRowCfg(cfgCopy));
  }
};
