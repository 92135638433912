import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import InverterSelect from "./InverterSelect";
import SelectedInverters from './SelectedInverters';
import { getAllInverters } from "Redux/actions/electrical/electricalData";
const InverterSearch = (props) => {
  const dispatch = useDispatch();

  const [displayedInverters, setDisplayedInverters] = useState([]);
  const [searchString, setSearchString] = useState("");

  const filterInverters = (string) => {
    let inverters = props.inverters;

    let query = string.toLowerCase();
    inverters = inverters.filter((table) => {
      return table.name.toLowerCase().indexOf(query) !== -1;
    });
  
    
         return setDisplayedInverters(inverters);
    
 
  };

  const changeHandler = (e) => {
    setSearchString(e.target.value);
    filterInverters(searchString);
  };

  const resetString = () => {
    return setSearchString("");
  };

  useEffect(() => {
    dispatch(getAllInverters());
    //eslint-disable-next-line
  }, []);
 

  return (
    <div className="inverter-search">
      {props.selectedInverter !== undefined ? (
        <h1 className="title inverter-title">Selected Inverter:</h1>
      ) : (
        <h1 className="title inverter-title">Select your Inverter</h1>
      )}
      {props.selected !== undefined ? props.selected.name : null}

      <SelectedInverters />

      <input
        type="text"
        className="search-for-inverters"
        placeholder="Search for inverter"
        value={searchString}
        onChange={changeHandler}
      />

      {searchString && displayedInverters.length ? (
        <InverterSelect
          displayedInverters={displayedInverters}
          resetString={resetString}
        />
      ) : null}
      <button id="clear" onClick={resetString} className="close-tables">
        clear
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    inverters: state.electrical.inverters,
    selectedInverter: state.electrical.selectedInverter,
  };
};
export default connect(mapStateToProps)(InverterSearch);
