import axios from "axios";
import { successMessage,errorMessage } from "../notificationActions";

export const submitProject = (state, username) => {
  //we need to extract the coordinates out of the geojson objects.

  let copy = { ...state };
  copy.username = username
  copy.ProjectData.project_id = state.project_id
  copy.SATRowInstances.project_id = state.project_id
  delete copy.projectLandAreas;
  delete copy.keepouts;
  delete copy.motorPiles;
  delete copy.state;
  delete copy.pileLocations;
  delete copy.tableLocations;
  delete copy.savedSatRowCfg;
  delete copy.siteBounds;
  delete copy.statusMessage;
  delete copy.totalArea;
  delete copy.keepoutsArea;
  delete copy.updatedAreas;
  
  
  axios.post(
    `${process.env.REACT_APP_BACKEND}/projects/updateProjectData`,
    copy,
   
  )
  .then(res => {
    console.log(res.data)
    successMessage("Your project was saved successfully!");
  })
  .catch(err => {
    console.log("Something went really wrong.", err);
    errorMessage("Something went wrong and you work was not saved.")
  })

};

// const saveTableLocations = async (data, project_id, satRowCfg) => {
//   let coords = [];
//   for (let table of data) {
//     let obj = {
//       project_id: project_id,
//       sat_cfg_id: satRowCfg,
//       n_coord: table[1],
//       e_coord: table[2],
//       z_coord: table[3],
//       tt_slope: 0.69,
//     };
//     coords.push(obj);
//   }
//   let sender = {
//     payload: coords,
//   };
 
//   axios
//     .post(`${process.env.REACT_APP_BACKEND}/api/save/tableLocation`, sender)
//     .then((res) => {
//       console.log(res.data);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
