import React from "react";
import { connect,useDispatch } from "react-redux";
import { addToSelectedInverters,setSelectedInverter } from "Redux/actions/electrical/electricalData";
import  {setInverterId} from "Redux/actions/ArmadilloActions";
import { toast } from "react-toastify";


//components
import Module from '../modules/Module';
const InverterSelect = (props) => {
  const  inverterAddedMessage = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  
  const dispatch = useDispatch();
  const inverterClickHandler = (inverter) => {
    
    dispatch(addToSelectedInverters(inverter))
    dispatch(setSelectedInverter(inverter));
    let copy = {...props.inverterInstances}
    copy.inverter_id = inverter.id
    dispatch(setInverterId(copy))
   
   
    inverterAddedMessage(`${inverter.name}has been added to project.` )
    props.resetString()
  };

  return (
    <div className="inverter-select">
    
      {props.displayedInverters.length < 1 ||
      props.displayedInverters.length > 200 ? (
        <div className="placeholder-div"></div>
      ) : (
        props.displayedInverters.map((inverter,i) => {
          return (
            <Module key={i} data={inverter} function={() =>inverterClickHandler(inverter)} />
          );
        })
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    selectedInverter: state.electrical.selectedInverter,
    inverterInstances: state.armadillo.InverterInstances
   
  }
}
export default connect(mapStateToProps)(InverterSelect);
