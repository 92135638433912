import React,{ useState,useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {validateUser} from 'Redux/actions/authenticationActions';


//components
import Login from '../authentication/Login';
import Register from '../authentication/Register';


const Splash = () => {
    const [showLogin, setShowLogin] = useState(true)
    const dispatch= useDispatch();
    const toggleLoginDisplay = () => {
        setShowLogin(!showLogin);
    }

    useEffect(() => {
        dispatch(validateUser())
        //eslint-disable-next-line
    }, [])
 
    return (
        <div className='splash-page'>
       
            {showLogin ? <Login toggle={toggleLoginDisplay} /> : <Register  toggle={toggleLoginDisplay}/> }
           
           
            
            
        </div>
    );
}

export default Splash;
