import axios from "axios";

export const getModuleMakers = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_MODULE_MASTER}/manufacturers/`)
    .then((res) => {
      dispatch({
        type: "GET_ALL_MANUFACTURERS",
        payload: res.data.manufacturers,
      });
    })
    .catch((err) => {
      console.log("There was an error fetching module manufacturers", err);
    });
};

export const getModulesByManufacturer = (id) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_MODULE_MASTER}/modules/${id}`)
    .then((res) => {
      if (res.data.modules !== undefined) {
        let manufacturer = res.data.modules[0].manufacturer;

        dispatch({
          type: "SET_DISPLAYED_MODULES",
          payload: res.data.modules,
          manufacturer: manufacturer,
        });
      }
    })
    .catch((err) => {
      console.log("There was an error fetching modules by manufacturer", err);
    });
};

export const getModuleById = (id) => async (dispatch) => {
  if (id) {
    try {
      let module = await axios.get(
        `${process.env.REACT_APP_MODULE_MASTER}/modules/id/${id}`,
      );
    
      dispatch({
        type: "SET_SELECTED_MODULE",
        payload: module.data.module[0],
      })
    }catch(err) {
      console.log(err)
    }
  
  }
};

export const getInverterById = (id) => async (dispatch) => {
  if (id) {
    let res = await axios.get(
      `${process.env.REACT_APP_MODULE_MASTER}/inverters/id/${id}`,
    );
    dispatch({
      type: "SET_SELECTED_INVERTER",
      payload: res.data.inverter,
    })
  }
};

export const getAllModules = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_MODULE_MASTER}/modules/`)
    .then((res) => {

      dispatch({
        type: "GET_ALL_MODULES",
        payload: res.data.modules,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllInverters = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_MODULE_MASTER}/inverters/`)
    .then((res) => {
      dispatch({
        type: "GET_ALL_INVERTERS",
        payload: res.data.inverters,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addToSelectedModules = (module) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_MODULE",
    payload: module,
  });
};

export const setSelectedModule = (module) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_MODULE",
    payload: module,
  });
};

export const setSelectedInverter = (inverter) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_INVERTER",
    payload: inverter,
  });
};

export const addToSelectedInverters = (inverter) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_INVERTER",
    payload: inverter,
  });
};

export const setDcAcRatio = (dcac) => (dispatch) => {
  if (dcac > 1 && dcac < 2) {
    dispatch({
      type: "SET_DCAC_RATIO",
      payload: dcac,
    });
    return true;
  }
};

export const clearSelectedManufacturer = () => (dispatch) => {
  dispatch({
    type: "CLEAR_SELECTED",
  });
};

export const clearDisplayedModules = () => (dispatch) => {
  dispatch({
    type: "CLEAR_DISPLAYED",
  });
};
