import axios from "axios";
import {
  errorMessage,
  successMessage,
} from "Redux/actions/notificationActions";


export const getUserProjects = (user) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_BACKEND}/projects/projects`, user)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: "SET_USER_PROJECTS",
          payload: res.data.projects,
        });
      } else {
        console.log("User has no projects.");
      }
    })
    .catch((err) => {
      console.log("User has no projects.", err.message);
    });
};

export const setLayout = (type) => (dispatch) => {
  dispatch({
    type: "SET_LAYOUT_TYPE",
    payload: type,
  });
};

export const setProjectData = (data) => (dispatch) => {
  dispatch({
    type: "SET_PROJECT_DATA",
    payload: data,
  });
};

export const getProjectId = (id) => (dispatch) => {
  dispatch({
    type: "SET_PROJECT_ID",
    payload: id,
  });
};

export const setLayoutCfg = (data) => (dispatch) => {
  dispatch({
    type: "SET_LAYOUT_CFG",
    payload: data,
  });
};

export const setSATRowCfg = (data) => (dispatch) => {
  //  console.log(data)
  dispatch({
    type: "SET_SATROW_CFG",
    payload: data,
  });
};

export const setInverterId = (data) => (dispatch) => {
  dispatch({
    type: "SET_INVERTER_ID",
    payload: data,
  });
}

export const getBoundingBox = (data) => (dispatch) => {
  dispatch({
    type: "SET_BOUNDING_BOX",
    payload: data,
  });
};

export const setTableLocations = (data) => (dispatch) => {
  dispatch({
    type: "LOAD_TABLE_LOCATIONS",
    payload: data,
  });
};

export const updateProjectState = (newState, prevState) => (dispatch) => {
  for (let property in prevState) {
    for (let prop in newState) {
      if (property === prop) {
        prevState[property] = newState[prop];
      }
    }
  }
  dispatch({
    type: "UPDATE_PROJECT_STATE",
    payload: prevState,
  });
};
export const clearLandAreas = () => (dispatch) => {
  dispatch({
    type: "CLEAR_LAND_AREAS"
  })
}
export const deleteProject = (project) => (dispatch) => {
  let obj = {
    id: project.id,
    userId: project.project_data.ProjectData.owner_id,
  };
  axios
    .post(`${process.env.REACT_APP_BACKEND}/projects/deleteProject`, obj)
    .then((res) => {
      dispatch({
        type: "SET_USER_PROJECTS",
        payload: res.data.projects,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveSatRowCfg = (satRowCfg, user) => (dispatch) => {
  let payload = {
    users_id: user.id,
    tableCfg: satRowCfg,
  };

  axios
    .post(`${process.env.REACT_APP_BACKEND}/tables/saveTable`, payload)
    .then((res) => {
      
      if (res.data.message) {
        console.log(res.data.message)
        dispatch({
          type: "TOGGLE_OVERWRITE_WARNING",
          payload: true,
        });
        // errorMessage("That table already exists.");
       
        dispatch({
          type: "FETCH_SAVED_SATROWS",
          payload: res.data.savedCfg,
        });
      } else {
        successMessage(`${satRowCfg.sat_table_name} has been saved.`);
        dispatch({
          type: "FETCH_SAVED_SATROWS",
          payload: res.data.savedCfg,
        });
        dispatch({
          type: "SET_SATROW_ID",
          payload: res.data.id,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setSelectedTable = (table) => (dispatch) => {
  dispatch({
    type: "SET_SELECTED_TABLE",
    payload: table
  });
}



export const toggleOverwriteWarning = (payload) => (dispatch) => {
  dispatch({
    type: "TOGGLE_OVERWRITE_WARNING",
    payload: payload
  });
}

export const updateSavedCfg = (savedCfg) => (dispatch) => {
  axios.put(`${process.env.REACT_APP_BACKEND}/tables/editTable`, savedCfg)
  .then(res => {
    successMessage("Your table has been updated.")
    // dispatch({
    //   type: "FETCH_SAVED_SATROWS",
    //   payload: res.data.savedCfg,
    // });
  })
  .catch(err => {
    console.log("There was an error updating a saved configuration.")
    errorMessage("There was an error updating your table.")
  })
}

export const deleteSavedCfg = (cfg) => (dispatch) => {
  let obj = {
    id: cfg.id,
    user: cfg.users_id,
  };
  axios
    .post(`${process.env.REACT_APP_BACKEND}/tables/deleteSaved`, obj)
    .then((res) => {
      dispatch({
        type: "FETCH_SAVED_SATROWS",
        payload: res.data.savedCfg,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
