import utmObj from "utm-latlng";
import { uploadGeneratedFiles } from "../userActions";
import { submitProject } from "./submitProject";

let utm = new utmObj("WGS 84");
let precision = 2;

export const latLngtoUtm = (
  latLngArray,
  motorPiles,
  user,
  state,
  project_id,
  satRowCfg,
  armadillo,
) => {
  let pointLocations = [];
  let motorPilesForCsv = [];

  let files = [];
  let pointId = 1;
  let pointSatRowId = 1
 
  for (let point of latLngArray) {
    
    let elevation = point.properties.elevation;
    let arrayArea = point.properties.arrayArea;
    let arrayColumn = point.properties.arrayColumn;
    let arrayRow = point.properties.arrayRow;
    let type = point.properties.type;
    let rank = point.properties.rank;
    let n_piles = armadillo.SATRowCfg.n_piles
   
    let pointCoords = point.geometry.coordinates;
    let utms = utm.convertLatLngToUtm(
      pointCoords[1],
      pointCoords[0],
      precision,
    );
    let resultArray = Object.values(utms);
    let newResult = resultArray.slice(0, 2);
    newResult.unshift(pointId);
    newResult.push(elevation);
    newResult.push(type);
    newResult.push(pointSatRowId)
    newResult.push(arrayArea);
    newResult.push(arrayColumn);
    newResult.push(arrayRow);
    newResult.push(rank);

    pointLocations.push(newResult);
    if(point.properties.rank >= n_piles) {
      pointSatRowId ++
    }
    pointId++;
  }
  let satRowId = 1;
  console.log(satRowId)
  for (let motorPile of motorPiles) {
    let elevation = motorPile.properties.elevation;
    let arrayArea = motorPile.properties.arrayArea;
    let arrayColumn = motorPile.properties.arrayColumn;
    let arrayRow = motorPile.properties.arrayRow;
    let type = motorPile.properties.type;
    let rank = motorPile.properties.rank;
    let torqueTubeSlope = "TTS";

    let motorPileCoords = motorPile.geometry.coordinates;
    let utms = utm.convertLatLngToUtm(
      motorPileCoords[1],
      motorPileCoords[0],
      precision,
    );
    let resultArray = Object.values(utms);
    let newResult = resultArray.slice(0, 2);
    newResult.unshift(satRowId);
    newResult.push(elevation);
    newResult.push(type);
    newResult.push(arrayArea);
    newResult.push(arrayColumn);
    newResult.push(arrayRow);
    newResult.push(rank);
    newResult.push(torqueTubeSlope);

    motorPilesForCsv.push(newResult);
    satRowId++;
  }
  let pointCsv = writePointsCsv(pointLocations);
  let tableCsv = writeTableCsv(motorPilesForCsv);
 
  submitProject(armadillo, user.username);
  files.push(pointCsv);
  files.push(tableCsv);
  if (files.length > 1) {
    uploadGeneratedFiles(user, state, files);
  }
  
};

const writePointsCsv = (points) => {
  let pointCsvContent = points.map((point) => point.join(",")).join("\n");

  let pointData = new File([pointCsvContent], "pointData.csv", {
    type: "text/csv;charset=utf-8",
  });

  return pointData;
};

const writeTableCsv = (tableCentroids) => {
  let tableCsvContent = tableCentroids
    .map((centroid) => centroid.join(","))
    .join("\n");

  let tableData = new File([tableCsvContent], "tableData.csv", {
    type: "text/csv;charset=utf-8",
  });

  return tableData;
};
