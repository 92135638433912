import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
// import layoutLoop from "Redux/actions/armadillo/layoutLoop";
import { layoutCall } from "Redux/actions/armadillo/layoutCall";
import { setProjectData } from "Redux/actions/ArmadilloActions";
import { setLoadingTrue } from "Redux/actions/loadingActions";
const mapStateToProps = (state) => {
  return {
    projectData: state.armadillo.ProjectData,
    satRowData: state.armadillo.SATRowCfg,
    structuralData: state.armadillo.SATRowStructural,
    layoutData: state.armadillo.LayoutCfg,
    armadillo: state.armadillo,
    keepouts: state.project.keepouts,
    projectLand: state.project.projectLandUTM,
    module: state.electrical.selectedModule,
    inverter: state.electrical.selectedInverter,
    rotate: state.rotation.rotate,
    user: state.user.userData
  };
};

const ProjectConfirmationModal = (props) => {
  const dispatch = useDispatch();
  const makeTheCall = () => {
    props.toggleConfirm();
    dispatch(setLoadingTrue())
    dispatch(
      layoutCall(
        props.projectLand,
        props.armadillo.SATRowCfg.sat_row_length,
        props.armadillo.SATRowCfg.sat_row_width,
        props.armadillo.SATRowCfg.n_piles,
        props.armadillo.LayoutCfg.gap_NS_distance,
        props.armadillo.LayoutCfg.gap_EW_distance,
        props.projectData,
        props.rotate,
        props.user
      ),
    );
  };
  const inverter = props.inverter;
  const module = props.module;
  const projectData = props.projectData;
  const satRowData = props.satRowData;
  const layoutData = props.layoutData;
  const structuralData = props.structuralData;

  const updateData = (projectData, module, inverter) => {
    if (projectData.inverter_name === "") {
      let copy = { ...projectData };
      copy.module_name = module.name;
      copy.inverter_name = inverter.name;
     
      dispatch(setProjectData(copy));
    }
  };

  useEffect(() => {
    updateData(projectData, module, inverter)
    //eslint-disable-next-line
  },[])


  return (
    <div className="project-confirmation-modal modal">
      <h3 className="project-name">{projectData.project_name}</h3>

      <div className="project-table-data">
        <h3 className="table-name">Table name: {satRowData.sat_table_name}</h3>
      </div>

      <div className="project-electrical-data">
        <p className="selected-module">
          Selected Module: {props.module.name}
        </p>
        <p className="selected-inverter">
          Selected Inverter: {props.inverter.name}
        </p>
      </div>

      <div className="project-pvLayout-data">
        <h3 className="ns-slope-tol">NS gap:{layoutData.gap_NS_distance}</h3>
        <h3 className="ns-slope-tol">EW gap:{layoutData.gap_EW_distance}</h3>
      </div>

      <div className="elevation-data">
        <h3 className="ns-slope-tol">
          NS slope tolerance:{structuralData.ns_slope_tol_2}
        </h3>
        <h3 className="ns-slope-tol">
          EW slope tolerance:{structuralData.ew_slope_tol_2}
        </h3>
      </div>

      <div className="buttons">
        <button className="close" onClick={props.toggleConfirm}>
          Close
        </button>
        <button className="submit-project" onClick={makeTheCall}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(ProjectConfirmationModal);
