import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";
import DeckGL from "@deck.gl/react";
import ReactMapGL, { Layer, MapContext, Source } from "react-map-gl";
// import { latLngtoUtm } from "../../Redux/actions/armadillo/latLngtoUtm";
import {
  deckStyles,
  skyLayer,
  projectLandLayer,
  keepoutLayer,
  getTableLayer,
  getPileLayer,
} from "./mapStyles";
import { latLngtoUtm } from "Redux/actions/armadillo/latLngtoUtm";
import { setLoadingFalse, setLoadingTrue } from "Redux/actions/loadingActions";

//style
import scss from "./reactMap.module.scss";
//components
import LoadingModal from "components/modals/Loading/LoadingModal";

const ReactMap = (props) => {
  let features = Object.values(props.projectLand);
  const [mapStyle] = useState("mapbox://styles/mapbox/satellite-v9");


  const viewportCenter = props.siteBounds[0].properties.center;
  const dispatch = useDispatch();

  const [geoData, setGeoData] = useState({
    geojson: {
      type: "FeatureCollection",
      features: features,
    },
  });

  const [keepouts, setKeepouts] = useState({
    geojson: {
      type: "FeatureCollection",
      features: props.keepouts,
    },
  });

  const [tableLocations, setTableLocations] = useState({
    geojson: {
      type: "FeatureCollection",
      features: [],
    },
  });

  const [pilePoints, setPilePoints] = useState({
    geojson: {
      type: "FeatureCollection",
      features: [],
    },
  });

  const totalArea = props.armadillo.totalArea;
  let zoom;
  if (totalArea > 10000) {
    zoom = 11.5;
  } else {
    zoom = 13;
  }
  //this is an array of latLngs that represent the centroid of the entire region.
  const [viewport, setViewPort] = useState({
    longitude: viewportCenter.lng,
    latitude: viewportCenter.lat,
    zoom: zoom,
    bearing: 0,
    maxPitch: 85,
  });

  const landLayer = projectLandLayer(geoData, setGeoData);
  const keepoutsLayer = keepoutLayer(keepouts, setKeepouts);
  const tableLayer = getTableLayer(tableLocations, setTableLocations);
  const pileLocationsLayer = getPileLayer(pilePoints, setPilePoints);

  const mapRef = useRef();
 

  useEffect(() => {
    if (props.dillo) {
      setTableLocations({
        geojson: {
          type: "FeatureCollection",

          features: props.dillo,
        },
      });
    }
    if (props.pileLocations) {
      setPilePoints({
        geojson: {
          type: "FeatureCollection",

          features: props.pileLocations,
        },
      });
    }
  

    //eslint-disable-next-line
  }, [props.dillo, props.pileLocations, props.projectLand]);
  useEffect(() => {
    if (props.pileLocations.length) {
      dispatch(setLoadingTrue());
    }
    dispatch(setLoadingFalse());

    //eslint-disable-next-line
  }, []);

  

  const saveData = async () => {
    //if there are pile points
    //loop through and query elevation for each point
    for (let pilePoint of props.pileLocations) {
      let lngLat = {
        lng: pilePoint.geometry.coordinates[0],
        lat: pilePoint.geometry.coordinates[1],
      };
      let elevation = await mapRef.current.getMap().queryTerrainElevation(lngLat, {
        useExaggeration: false,
      });
      //assign the elevation as a property on the point
      pilePoint.properties.elevation = elevation;
    }
    for (let motorPile of props.motorPiles) {
      let lngLat = {
        lng: motorPile.geometry.coordinates[0],
        lat: motorPile.geometry.coordinates[1],
      };
      
      let elevation = mapRef.current.getMap().queryTerrainElevation(lngLat, {
        useExaggeration: false,
      });
      //assign the elevation as a property on the point
      motorPile.properties.elevation = elevation;
    }
    let user = props.user;
    let projectData = props.armadillo.ProjectData;
    let satRowCfg = props.satRowCfg;
    let project_id = props.project_id;

    //these variables are used to construct the path for the csv to be uploaded to.

    latLngtoUtm(
      props.pileLocations,
      props.motorPiles,
      user,
      projectData,
      project_id,
      satRowCfg.id,
      props.armadillo,
    );

    //this function will convert the lat/lng coords to UTM
    //These will be written to a CSV file.
  };

  const onMapLoad = useCallback((evt) => {
    document
      .getElementById("deckgl-wrapper")
      .addEventListener("contextmenu", (evt) => evt.preventDefault());
    let map = evt.target;

    map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
  }, []);

  const getMouseCoords = (e) => {
    //this function queries elevation at mouse coords
    //if there is a layer there it will return the object
    //as well as elevation
    if (e.layer) {
      console.log(e.object);
    }
    let lngLat = { lng: e.lngLat[0], lat: e.lngLat[1] };

    let elevation = mapRef.current
      .getMap()
      .queryTerrainElevation(lngLat, { useExaggeration: false });
    console.log(elevation);
    //only console logs this information for now.
  };

  const handleViewPortChange = useCallback(
    (newViewport) => () => setViewPort(newViewport),
    [],
  );

  return (
    <div>
      <DeckGL
        initialViewState={viewport}
        controller={true}
        layers={[landLayer, keepoutsLayer, tableLayer, pileLocationsLayer]}
        getCursor={() => "pointer"}
        style={deckStyles}
        height={"100vh"}
        width={"100vw"}
        ContextProvider={MapContext.Provider}
        onClick={getMouseCoords}
      >
        <ReactMapGL
          {...viewport}
          id="map"
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          mapStyle={mapStyle}
          onLoad={onMapLoad}
          onViewportChange={handleViewPortChange}
          ref={mapRef}
        >
          <Layer {...skyLayer} />
          <Source
            id="mapbox-dem"
            type="raster-dem"
            url="mapbox://mapbox.mapbox-terrain-dem-v1"
            tileSize={512}
            maxzoom={14}
          />

          {props.loading && !props.viewing ? (
            <button className={scss["save-project"]} onClick={saveData}>
              Save Project
            </button>
          ) : null}

          {props.loading ? <LoadingModal /> : null}
        </ReactMapGL>
      </DeckGL>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    keepouts: state.armadillo.keepouts.keepouts,
    dillo: state.armadillo.tableLocations.tables,
    pileLocations: state.armadillo.pileLocations.piles,
    motorPiles: state.armadillo.motorPiles,
    armadillo: state.armadillo,
    project_id: state.armadillo.project_id,
    sat_row_id: state.armadillo.sat_row_id,
    satRowCfg: state.armadillo.SATRowCfg,
    projectLand: state.armadillo.projectLandAreas,
    siteBounds: state.armadillo.siteBounds,
    user: state.user.userData,
    loading: state.loading.showLoading,
    viewing: state.loading.viewing
  };
};

export default connect(mapStateToProps)(ReactMap);
