import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { setSATRowCfg } from "Redux/actions/ArmadilloActions";
import { getModuleById } from "Redux/actions/electrical/electricalData";
import { getTrackerDimensions } from "Redux/actions/armadillo/getTrackerDimensions";

//components
import SavedSatRows from "../../Routes/createProject/tools/savedSatRows/SavedSatRows";
//styles
import scss from "./TrackerLayoutModal.module.scss";
import CreateNew from "./CreateNew";

const TrackerLayoutModal = (props) => {
  const dispatch = useDispatch();

  const [satRowCfg, setCfg] = useState({
    ...props.armadillo,
  });

  const simpleChangeHandler = (e) => {
    setCfg({
      ...satRowCfg,
      [e.target.name]: e.target.valueAsNumber || e.target.value,
    });
  };

  const setModuleId = (data) => {
    let copy = { ...satRowCfg };
    copy.module_id = data;
    setCfg(copy);
  };

  const configureTracker = (e) => {
    props.toggleModals(e, satRowCfg);

    dispatch(setSATRowCfg(satRowCfg));
  };

  const [createNew, setCreateNew] = useState(false);
  const toggleCreation = () => {
    setCreateNew(!createNew);
  };
  useEffect(() => {
    if (props.selectedModule === undefined) {
      dispatch(getModuleById(satRowCfg.module_id));
    }
    //eslint-disable-next-line
  }, [satRowCfg, props.selectedModule]);

  useEffect(() => {
    dispatch(getTrackerDimensions(props.selectedModule, satRowCfg));
    setCfg({ ...props.armadillo });
    //eslint-disable-next-line
  }, [props.selectedModule]);

  return (
    <div className={scss["tracker-layout-modal"]}>
      <h1 className={scss["section-heading"]}>Table configuration</h1>
      {props.saved.length > 0 && !createNew ? <SavedSatRows /> : null}
      {createNew ? (
        <CreateNew
          setCfg={setCfg}
          satRowCfg={satRowCfg}
          simpleChangeHandler={simpleChangeHandler}
          setModuleId={setModuleId}
          armadillo={props.armadillo}
        />
      ) : null}

      <button onClick={toggleCreation} className={scss["create-new-cfg"]}>
        {createNew ? "Done" : "Create New"}
      </button>

      {createNew ? null : (
        <div className={scss["buttons"]}>
          <button className="table close" onClick={props.toggleModals}>
            Close
          </button>

          <button
            className="table save-and-close"
            onClick={(e) => configureTracker(e)}
          >
            Save and close
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    armadillo: state.armadillo.SATRowCfg,
    saved: state.user.savedSatRowCfgs,
    selectedModule: state.electrical.selectedModule,
  };
};

export default connect(mapStateToProps)(TrackerLayoutModal);
