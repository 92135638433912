import React, { useState,useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { setLayoutCfg } from "Redux/actions/ArmadilloActions";
import { errorMessage } from "Redux/actions/notificationActions";


const PvLayoutModal = (props) => {
  const dispatch = useDispatch();
  const [pvLayoutData, setPvLayoutData] = useState({
    ...props.armadillo
  });

  const [overridingLayoutCfg, setOverridingLayoutCfg] = useState(false);
  const toggleLayoutCfgOverride = (e) => {
    e.preventDefault();
    setOverridingLayoutCfg(!overridingLayoutCfg);
  };

  const changeHandler = (e) => {
    setPvLayoutData({
      ...pvLayoutData,
      [e.target.name]: e.target.valueAsNumber || e.target.value,
    });
  };
  const configureLayout = (e) => {
    props.toggleModals(e,pvLayoutData);
    dispatch(setLayoutCfg(pvLayoutData))    
  };

  const calculateGcr = (module,pvLayoutData) => {
    if(module !== undefined) {
      let gap_width = module.length*(1 / pvLayoutData.gcr - 1)
      let cfgCopy = {...pvLayoutData}
      cfgCopy.gap_EW_distance = gap_width
      setPvLayoutData(cfgCopy)
    }else {
      errorMessage("You must configure a SAT row first.")
    }
   
  }

  useEffect(() => {
    calculateGcr(props.selectedModule, pvLayoutData)
    //eslint-disable-next-line
  }, [])


  return (
    <div className="pv-layout-modal creation-modal">
      <form className="pv-layout-form">
        <h4 className="section-title">PV array layout</h4>
     

        <div className="tracker-horizontal-info">
          {/* <div className="input-container">
            <label htmlFor="azimuth">Azimuth</label>
            <input
              type="number"
              className="tracker-input"
              placeholder={pvLayoutData.azimuth}
              name="azimuth"
              min="0"
              max="360"
              value={pvLayoutData.azimuth}
              onChange={changeHandler}
            />
          </div>
          <div className="input-container">
            <label htmlFor="skew_angle">Skew</label>
            <input
              type="number"
              className="tracker-input"
              placeholder={pvLayoutData.skew}
              name="skew"
              min="0"
              max="360"
              value={pvLayoutData.skew}
              onChange={changeHandler}
            />
          </div> */}
         <div className="layout-cfg-driven-inputs">
         <div className="input-container">
            <label htmlFor="NS_gap">NS Gap Distance</label>
            <input
              type="number"
              className="tracker-input NS-gap"
              placeholder={pvLayoutData.gap_NS_distance}
              name="gap_NS_distance"
              value={pvLayoutData.gap_NS_distance}
              onChange={changeHandler}
            />
          </div>

          <div className="input-container">
            <label htmlFor="gcr">GCR (%)</label>
            <input
              type="number"
              className="tracker-input EW-gap"
              placeholder={pvLayoutData.gcr}
              value={parseFloat(pvLayoutData.gcr)}
              name="gcr"
              onChange={changeHandler}
              onBlur={() => calculateGcr(props.selectedModule,pvLayoutData)}
            />
          </div>
         </div>

          <div className="input-container">
            <label htmlFor="EW_gap">EW Gap Distance</label>
            <input
              type="number"
              className="tracker-input EW-gap"
              placeholder={pvLayoutData.gap_EW_distance}
              value={parseFloat(pvLayoutData.gap_EW_distance.toFixed(2))}
              name="gap_EW_distance"
              onChange={changeHandler}
              disabled={!overridingLayoutCfg}
            />
          </div>

          <div className="button-container">
          <button onClick={(e) => toggleLayoutCfgOverride(e)} className="override-pv-layout">
            {overridingLayoutCfg ? "Done" : "Edit"}
          </button>
          </div>

          

         
        </div>
      </form>
      <div className="buttons">
        <button className="layout" onClick={props.toggleModals}>
          Close
        </button>
        <button
          className="layout"
          onClick={(e) => configureLayout(e)}
        >
          Save and Close
        </button>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    project: state.project,
    armadillo: state.armadillo.LayoutCfg,
    rotation: state.rotation.rotate,
    selectedModule: state.electrical.selectedModule
    
  };
};
export default connect(mapStateToProps)(PvLayoutModal);
