import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { userLogin } from "Redux/actions/authenticationActions";
import { ToastContainer } from "react-toastify";
import {
  errorMessage,
  successMessage,
} from "Redux/actions/notificationActions";
const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { toggle } = props;

  const [loginCredentials, setCredentials] = useState({
    email: "",
    username: "",
    password: "",
    remember: false,
  });
  //initial state of login credentials

  const [loginName, setLoginName] = useState("");
  //login name is seperated out because there is a check to see if the
  //user is wanting to login with an email or username.
  const nameTypeHandler = (e) => {
    setLoginName(e.target.value);
  };

  const inputHandler = (e) => {
    setCredentials({ ...loginCredentials, [e.target.name]: e.target.value });
  };

  const toggleRememberMe = () => {
    setCredentials({
      ...loginCredentials,
      remember: !loginCredentials.remember,
    });
  };

  //login by username or email
  const setLoginNameType = (string) => {
    if (string.length >= 8) {
      if (string.includes("@")) {
        setCredentials((loginCredentials) => ({
          ...loginCredentials,
          email: loginName,
        }));
      } else {
        setCredentials((loginCredentials) => ({
          ...loginCredentials,
          username: loginName,
        }));
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    //prevents the page from refreshing on submit
    let loginResult = await dispatch(userLogin(loginCredentials));
   
    //if the login information is valid, the response from the server is "true"
    if (loginResult.username) {
      //if true a validation toast shows, and the the user is navigated to their dashboard.
      successMessage("You did it");
      history.push(`/${loginResult.username}/dashboard`);
      // window.location = `/${loginCredentials.username}/dashboard`;
    } else {
      //otherwise they get an error toast
      errorMessage("Please check your credentials and try again.");
    }
  };

  return (
    <div className="login-component">
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form>
        <div className="input-container">
          <label htmlFor="username">E-mail or username</label>
          <input
            type="text"
            className="auth-input"
            name="loginName"
            value={loginName}
            onInput={nameTypeHandler}
            onBlur={() => setLoginNameType(loginName)}
          />
        </div>

        <div className="input-container">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="auth-input"
            name="password"
            value={loginCredentials.password}
            onInput={inputHandler}
          />
        </div>

        <div className="input-container">
          <label htmlFor="rememberMe">Remember me</label>
          <input
            type="checkbox"
            className="auth-input remember-me"
            name="rememberMe"
            value={loginCredentials.remember}
            onChange={toggleRememberMe}
          />
        </div>

        <div className="input-container">
          <button onClick={(e) => submitHandler(e)} className="login-button">
            Login
          </button>
        </div>
      </form>
      <p className="no-account-text">
        Don't have an account? Register <span onClick={toggle}>here</span>{" "}
      </p>

      <Link to="/forgotpassword" className="login-trouble">
        Having trouble logging in?
      </Link>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Login);
