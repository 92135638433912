import axiosAuth from "../../utils/AXIOS";
import axios from "axios";
import { successMessage } from "./notificationActions";

export const userLogin = (credentials) => async(dispatch) => {
  return axiosAuth
    .post(`${process.env.REACT_APP_BACKEND}/auth/login`, credentials)
    .then((res) => {
     
      if (res.status === 200) {
        dispatch({
          type: "SEND_LOGIN_CREDENTIALS",
          payload: res.data,
        });
        
        let user = res.data
        

        return user;
      }else {
        return false
      }
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const registerUser = (credentials) => async (dispatch) => {
  return axiosAuth
    .post(`${process.env.REACT_APP_BACKEND}/auth/register`, credentials)
    .then((res) => {
      
      
      if (res.status === 201) {
        successMessage("You have been registered! Please Login.")
        dispatch({
          type: "SEND_REGISTRATION_CREDENTIALS",
          payload: res.data,
        });
        return true;
      }else {
        return false;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const validateUser = () => async (dispatch) => {  
 await axiosAuth
    .get(`${process.env.REACT_APP_BACKEND}/auth/session`)
    .then((res) => {
      dispatch({
        type: "SET_USER_DATA",
        payload: res.data,
      });
      return res.data.id;
    })
    .catch((err) => {
      console.log("No user is logged in",err);
    });
};

export const logout = () => (dispatch) => {
  axiosAuth
    .get(`${process.env.REACT_APP_BACKEND}/auth/logout`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: "CLEAR_USER_DATA",
        payload: res.data,
      });
      window.location = "/";
    })
    .catch((err) => {
      console.log(err);
    });
};

export const forgotPassword = (email) => {
  axios
    .patch(`${process.env.REACT_APP_BACKEND}/auth/forgot-password`, { email })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const resetPassword = (password, token) => {
  console.log(password);
  axiosAuth
    .patch(`${process.env.REACT_APP_BACKEND}/auth/reset-password/${token}`, {
      password,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
