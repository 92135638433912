import { combineReducers } from "redux";
import tablesReducer from './tablesReducer';
import userReducer from './userReducer';
import projectReducer from './projectReducer';
import armadilloReducer from './ArmadilloReducer';
import electricalReducer from './electricalReducer';
import rotationReducer from './rotationReducer';
import loadingState from './loadingState';

export default combineReducers({
  tables: tablesReducer,
  user: userReducer,
  project: projectReducer,
  armadillo: armadilloReducer,
  electrical: electricalReducer,
  rotation: rotationReducer,
  loading: loadingState
  
});
