const initialState = {
  tables : [],
  selectedTable : undefined,
  tableDimensions: {},
  overwriteWarning: false
  
};

const tablesReducer = (state = initialState, action) => {
  switch (action.type) {
   case "GET_ALL_TABLES":
       return {
           ...state,tables: action.payload
       }
   case "SET_SELECTED_TABLE" :
     return {
       ...state,selectedTable: action.payload
     }
     case "GET_TABLE_DIMENSIONS": 
     return {
       ...state,tableDimensions: action.payload
     }

     case "TOGGLE_OVERWRITE_WARNING":
       return {...state,overwriteWarning: action.payload}
    default:
      return state;
  }
};

export default tablesReducer;
